.not-found {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;

  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__main {
    width: 130rem;
    max-width: 100%;
    position: relative;
    padding: 0 2.4rem;

    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  &__error-text {
    position: absolute;
    top: -1rem;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 9.6rem;
    text-transform: uppercase;
    line-height: 1;
    font-weight: 800;
    color: var(--not-found-error-text-color);
    z-index: -1;
  }

  &__img-block {
    text-align: center;
  }

  &__img {
    display: inline-block;
    height: 30rem;
    max-width: 100%;

    @media (max-width: 400px) {
      height: 25rem;
    }
  }

  &__text {
    text-align: center;
  }

  &__btn-group {
    display: flex;
    justify-content: center;
    gap: 2.4rem;
  }

  &__btn {
    display: inline-block;
    background-color: $color-primary-dark;
    color: $color-white;
    border-radius: 6px;
    padding: 1.2rem 3.6rem;
    text-decoration: none;
    text-align: center;

    @media (max-width: 350px) {
      padding: 1.2rem 2.4rem;
    }
  }
}
