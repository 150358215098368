.transaction-details {
  width: 50rem;
  max-width: 95%;
  margin: 4.8rem auto 9.6rem;
  background-color: var(--header-bg);
  padding: 2.4rem;

  &__back {
    margin-bottom: 1.2rem;
    cursor: pointer;

    & ion-icon {
      display: block;
      width: 1.6rem;
      height: 1.6rem;
      color: #bdbdbd;
    }
  }

  &__heading {
    font-size: 1.8rem;
    text-align: center;
    font-weight: 600;
    margin-bottom: 6px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;

    & span {
      cursor: pointer;

      & ion-icon {
        display: block;
      }
    }
  }

  &__heading-subtext {
    color: #828282;
    font-weight: 500;
    text-align: center;

    margin-bottom: 1.8rem;
  }

  &__infos {
    // display: flex;
    // flex-direction: column;
    // gap: 1.2rem;

    background-color: var(--crypto-block-header);
  }

  &__info {
    padding: 1.2rem 1.8rem;

    display: flex;
    align-items: center;
    gap: 1.2rem;

    @media (max-width: 450px) {
      flex-direction: column;
    }
  }

  &__info-key {
    flex: 0 0 12rem;
    text-transform: uppercase;

    @media (max-width: 450px) {
      flex: initial;
    }
  }

  &__info-right {
    flex: 1;

    display: flex;
    align-items: center;
    gap: 1.2rem;
  }

  &__info-text {
    word-break: break-all;
  }
}

// .InfoDate {
//   font-size: 14px;
//   color: #828282;
// }
