.otp-medium {
  &__heading {
    font-size: 1.6rem;
    font-weight: 400;
    margin-bottom: 1.6rem;
  }

  &__blocks {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    padding-left: 24px;
  }

  &__block {
    display: flex;
    align-items: center;
    gap: 1.6rem;
  }

  &__block-img {
    display: block;
    width: 2.8rem;
    height: 2.8rem;
    object-fit: cover;
  }

  &__block-name {
    margin-right: auto;
  }
}
