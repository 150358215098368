.blog-section {
  max-width: 100%;
  width: 149.6rem;
  margin: auto;

  padding: 0 4.8rem !important;
}

.blogs-wrapper {
  position: relative;

  &__control {
    position: absolute;
    top: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    border: none;

    cursor: pointer;
    border: none;
    color: var(--text-color);
    background-color: var(--util-bg);
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);

    &--left {
      left: 0;
      transform: translate(-80%, -50%);
    }

    &--right {
      right: 0;
      transform: translate(80%, -50%);
    }

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
    }
  }
}

.blogs {
  height: 35rem;

  display: flex;
  align-items: center;

  overflow-x: scroll;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
}

.blog {
  flex-shrink: 0;

  width: 35rem;
  height: 35rem;
  padding: 2.4rem;

  // position: absolute;
  // top: 0;
  // left: 0;

  transition: all 0.4s;

  &__img-block {
    text-align: center;
  }

  &__img {
    display: inline-block;
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
    object-fit: cover;
  }

  &__body {
    text-align: center;
  }

  &__title {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__text {
    font-weight: 500;
    margin-bottom: 1.8rem;
  }

  &__link {
    &:link,
    &:visited {
      color: $color-primary-dark;
      text-decoration: none;
      display: inline-block;
    }
  }
}
