.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.8rem;

  @media (max-width: 400px) {
    grid-template-columns: 1fr;
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 6px;

  &--md {
    gap: 1.6rem;
  }
}

.form-label {
  display: flex;
  align-items: center;
  gap: 6px;

  &--sm {
    font-size: 1.3rem;
  }
}

.form-bottom-label {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-size: 1.2rem;
  font-weight: 300;
}

.input-group {
  display: flex;
  height: 4.8rem;
  border: 1px solid var(--border);
  border-radius: 0.8rem;
  overflow: hidden;
  background-color: var(--form-bg);

  & button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    font-family: inherit;
    border: none;
    padding: 0 1.6rem;
    cursor: pointer;
    background-color: var(--form-button-inactive-bg);
    color: var(--form-button-inactive-text-color);
    min-width: 4.8rem;
  }

  &--1 button {
    background-color: $color-primary;
    color: #fff;
  }

  &--primary button {
    background-color: $color-primary-lighter;
    color: #fff;
  }

  & input {
    font-family: inherit;
    padding: 0 1.6rem;
    min-width: 0;
    flex: 1;
    border: none;
    outline: none;
    background-color: transparent;
    color: var(--text-color);
  }
}

.form-input-group {
  width: 100%;
  height: 4.8rem;
  padding: 0 1.6rem;
  background-color: var(--form-bg);
  border: 1px solid var(--border);
  border-radius: 0.8rem;
  overflow: hidden;

  display: flex;
  align-items: center;
  gap: 1.2rem;

  & input {
    flex: 1;
    min-width: 0;
    border: none;
    background-color: transparent;
    outline: none;
    font-family: inherit;
    color: var(--text-color);
  }

  & button {
    background-color: transparent;
    border: none;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      color: var(--text-color);
    }
  }
}

.form-input,
.form-select {
  display: block;
  width: 100%;
  height: 4.8rem;
  padding: 0 1.6rem;
  border: 1px solid var(--border);
  background-color: var(--form-bg);
  border-radius: 0.8rem;
  outline: none;
  font-family: inherit;
  color: var(--text-color);
}

textarea.form-input {
  height: auto;
  padding: 1.6rem;
}

.form-select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 50%;
}

.form-requirements {
  display: flex;
  gap: 1.2rem;
  flex-wrap: wrap;

  & div {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 1.2rem;
    font-weight: 300;
  }

  & ion-icon {
    display: block;
  }

  & div.active ion-icon {
    color: $color-accent;
  }
}

.checkbox {
  appearance: none;
  display: block;
  width: 3.6rem;
  height: 2.1rem;
  background-color: var(--account-bg-green);
  border-radius: 10rem;
  position: relative;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #3f7e05;
    border-radius: 50%;

    position: absolute;
    left: 3px;
    top: 3px;

    transition: all 0.3s;
  }

  &:checked {
    background-color: #397305;

    &::after {
      background-color: #fff;
      transform: translateX(100%);
    }
  }
}

.search-form {
  height: 4.8rem;
  border: 1px solid var(--border);
  background-color: var(--form-bg);
  border-radius: 0.6rem;

  display: flex;
  align-items: center;

  &__icon-block {
    height: 100%;
    width: 4.8rem;

    display: flex;
    align-items: center;
    justify-content: center;

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      color: var(--text-color);
    }
  }

  & input {
    flex: 1;
    min-width: 0;
    font-family: inherit;
    outline: none;
    padding-right: 1.8rem;
    border: none;
    background-color: transparent;
    color: var(--text-color);
  }
}

.select-box {
  height: 4.8rem;
  padding: 0 1.6rem;
  border: 1px solid var(--border);
  background-color: var(--form-bg);
  border-radius: 0.8rem;
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 1.2rem;

  &--md {
    height: 5.6rem;
  }

  &--sm {
    height: 4rem;
  }

  &--auto {
    height: auto;
    min-height: 4.8rem;
    padding: 1.2rem 1.6rem;
  }

  & img {
    display: block;
    width: 2.8rem;
    height: 2.8rem;
  }

  & ion-icon {
    margin-left: auto;

    display: block;
    width: 1.8rem;
    height: 1.8rem;
  }
}

.step-radio-btns {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

.radio {
  appearance: none;
  background-color: var(--radio-bg);
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  &:checked {
    width: 2rem;
    height: 2rem;
    background-color: rgba($color: $color-primary-darker, $alpha: 0.2);
  }

  &:checked::before {
    content: "";
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    background-color: $color-primary-darker;
    border-radius: 50%;
  }
}

.radio-1 {
  appearance: none;
  background-color: transparent;
  border: 2px solid #8c8c8c;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  cursor: pointer;

  position: relative;

  &:checked {
    border: 2px solid $color-primary-darker;
  }

  &:checked::before {
    content: "";
    display: block;
    width: 1rem;
    height: 1rem;
    background-color: $color-primary-darker;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.amount-blocks {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.2rem;

  & div {
    background-color: transparent;
    border: 1px solid var(--border);
    height: 3.2rem;
    font-size: 1.2rem;
    border-radius: 3px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    &.active,
    &:hover {
      border: none;
      color: #fff;
      background-color: $color-primary-darker;
    }
  }
}

.accept {
  display: flex;
  align-items: center;
  gap: 1.2rem;

  font-weight: 500;

  &__checkbox {
    flex-shrink: 0;
    appearance: none;
    cursor: pointer;
    display: block;
    width: 1.8rem;
    height: 1.8rem;
    background-color: #aaaaaa;
    border-radius: 3px;
    position: relative;

    &:checked {
      background-color: #448c04;
    }

    &::after {
      content: "\2713";
      display: block;
      color: #fff;
      font-weight: 700;
      font-size: 12px;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      display: none;
    }

    &:checked::after {
      display: block;
    }
  }
}

.form-disabled {
  background-color: var(--form-disabled) !important;
}

.copy {
  display: flex;
  align-items: center;
  gap: 0.8rem;

  padding: 3px 8px;
  border-radius: 6px;
  background-color: var(--header-bg);
  cursor: pointer;

  &--lg {
    padding: 0.6rem 1.2rem;
  }

  &--bold {
    background-color: $color-primary;
    color: #fff;
  }

  &--var {
    background-color: transparent;
    font-size: 1.6rem;
    color: $color-primary;
  }

  & ion-icon {
    display: block;
  }
}

.success-icon-block {
  width: 5.8rem;
  height: 5.8rem;
  border-radius: 50%;
  background-color: #ceddc0;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  & div {
    width: 5.2rem;
    height: 5.2rem;
    border-radius: 50%;
    background-color: #ddedcf;

    border: 2px solid #397305;

    display: flex;
    align-items: center;
    justify-content: center;

    & ion-icon {
      width: 2.4rem;
      height: 2.4rem;
      display: block;
      color: $color-primary;
    }
  }

  &--1 div {
    width: 3.6rem;
    height: 3.6rem;

    border: none;
    background-color: #397305;

    & ion-icon {
      color: #fff;
    }
  }
}

.stars {
  display: flex;
  align-items: center;
  gap: 6px;
}

.star {
  cursor: pointer;

  & ion-icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    color: var(--form-button-inactive-bg);

    transition: all 0.2s;
  }

  &:hover ion-icon,
  &--active ion-icon {
    color: $color-primary;
  }
}

.toggle {
  appearance: none;
  display: block;
  width: 6rem;
  height: 3rem;
  background-color: var(--account-bg-green);
  border-radius: 10rem;
  position: relative;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    background-color: $color-primary-dark;
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translateY(-50%);
    transition: all 0.3s;
  }

  &:checked {
    // background-color: #282828;

    &::after {
      left: calc(6rem - 2.2rem - 4px);
    }
  }
}

.checkbox-group {
  display: flex;
  align-items: center;
  gap: 4.8rem;

  &--spread {
    justify-content: space-between;
    gap: initial;
  }
}

.check-control {
  display: flex;
  align-items: center;
  gap: 1.6rem;
}

// External utility
.password {
  -webkit-text-security: disc;
  -moz-text-security: circle;
  text-security: circle;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
