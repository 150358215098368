.hero-section {
  background-color: var(--hero-bg);

  &__container {
    text-align: center;
    padding-top: 9.6rem;
  }

  &__content {
    max-width: 80rem;
    margin: auto;
  }

  &__heading {
    font-size: 4.8rem;
    line-height: 1.2;

    font-family: "Dancing Script", cursive;
    font-size: 6.2rem;
    font-weight: 700;

    margin-bottom: 1.2rem;

    @media (max-width: 450px) {
      font-size: 4.8rem;
    }
  }

  &__desc {
    font-size: 1.8rem;
    color: #397305;
    font-weight: 600;

    margin-bottom: 3.6rem;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;

    margin-bottom: 3.6rem;
  }

  &__action {
    height: 6.2rem;
    border-radius: 1.2rem;

    @media (max-width: 400px) {
      height: 5rem;
    }
  }

  &__img {
    width: 100%;

    &--light {
      display: var(--light-block);
    }

    &--dark {
      display: var(--dark-block);
    }
  }
}
