.home-footer {
  margin-top: 4.8rem;
  padding: 4.8rem 0;
  background-color: var(--footer-bg);

  &__container {
    display: grid;
    grid-template-columns: 35fr 20fr 20fr 20fr;
    gap: 4.8rem;

    @media (max-width: 750px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 500px) {
      grid-template-columns: 1fr;
    }
  }

  &__logo {
    height: 5rem;
    margin-bottom: 2.4rem;
  }

  &__heading {
    margin-bottom: 2.4rem;
    font-weight: 600;
  }

  &__text {
    margin-bottom: 1.2rem;

    display: flex;
    align-items: center;
    gap: 1.2rem;
  }

  &__socials {
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }

  &__social-link {
    &:link,
    &:visited {
      text-decoration: none;
      color: var(--text-color);
    }

    & ion-icon {
      display: block;
      width: 24px;
      height: 24px;
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    gap: 6px;

    list-style: none;
  }

  &__nav-link {
    &:link,
    &:visited {
      text-decoration: none;
      color: var(--text-color);
    }
  }

  &__footer {
    font-size: 1.4rem;
    grid-column: 1 / -1;
    text-align: center;

    & span {
      color: $color-red;
    }
  }
}

.dashboard-footer {
  text-align: center;
  color: $color-white;
  height: 16rem;
  padding-bottom: 2.4rem;
  background-size: cover;
  position: relative;

  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  gap: 1.6rem;

  @media (max-width: 750px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 1.2rem;
  }

  &__socials {
    display: flex;
    gap: 1.2rem;

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  &__arrow {
    cursor: pointer;

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 24px;
    }

    @media (max-width: 750px) {
      position: absolute;
      bottom: 1.2rem;
      left: 1.2rem;
    }
  }

  &__quick-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3.2rem;
    cursor: pointer;

    margin-bottom: 1.2rem;
  }

  & a {
    &:link,
    &:visited {
      color: $color-white;
      text-decoration: none;
    }
  }

  & p span {
    color: $color-red;
  }
}
