.giftcards-pg {
  display: flex;
  align-items: flex-start;
  gap: 3.6rem;

  @media (max-width: 1000px) {
    flex-direction: column;
  }

  &__main {
    flex: 1;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 500px) {
      flex-direction: column;
      gap: 1.8rem;
    }
  }

  &__header-main {
    display: flex;
    align-items: center;
    gap: 2.4rem;
  }

  &__heading {
    font-size: 2rem;
    font-weight: 700;
  }

  &__header-right {
    width: 25rem;

    @media (max-width: 500px) {
      width: 100%;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.4rem;

    @media (max-width: 1000px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 700px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 1.2rem;
    }

    // @media (max-width: 450px) {
    //   grid-template-columns: repeat(2, 1fr);
    // }
  }

  &__aside {
    flex: 0 0 35rem;

    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  &__quick-actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.4rem;

    @media (max-width: 450px) {
      grid-template-columns: 1fr;
    }
  }
}

.mt-giftcards {
  background-color: var(--header-bg);
  border-radius: 1.2rem;
  overflow: hidden;

  &__header {
    padding: 1.2rem 2.4rem;
    background-color: var(--mt-giftcards-header);
  }

  &__cards {
    display: flex;
    flex-direction: column;
    height: 17.5rem;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__card {
    display: flex;
    gap: 1.6rem;
    align-items: center;

    padding: 1.2rem 2.4rem;
    cursor: pointer;

    &:nth-child(odd) {
      background-color: var(--currency-bg-2);
    }

    &:nth-child(even) {
      background-color: var(--currency-bg-1);
    }
  }

  &__card-img {
    display: block;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    object-fit: cover;
  }
}

.giftcard-block {
  margin-left: 9.6rem;

  width: 50rem;
  max-width: 100%;

  @media (max-width: 800px) {
    margin-left: 4.8rem;
  }

  @media (max-width: 500px) {
    margin-left: 0;
  }

  &__countries {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2.4rem;

    @media (max-width: 500px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1.6rem;

      // & > div {
      //   width: 10rem;
      // }
    }
  }

  &__country {
    border: 2px solid var(--border);
    padding: 1.2rem;
    background-color: var(--header-bg);
    cursor: pointer;

    font-size: 1.6rem;

    &--active {
      border: 2px solid $color-primary;
    }

    // display: flex;
    // align-items: flex-end;
    // justify-content: space-between;

    display: flex;
    align-items: center;
    gap: 1.2rem;
  }

  // &__country-abbr {
  //   font-size: 1.2rem;
  // }

  // &__country-currency {
  //   font-weight: 300;
  // }

  &__country-img {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
  }

  &__receipts {
    list-style: none;

    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }

  &__receipt {
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }

  &__receipt-text {
    flex: 1;
    border: 1.5px solid var(--border);
    padding: 1.2rem 0;
    text-align: center;
    border-radius: 6px;
    font-weight: 600;
    cursor: pointer;
  }

  &__receipt--active &__receipt-text {
    border: 1.5px solid $color-primary;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
  }

  &__denominations {
    // display: flex;
    // align-items: center;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.8rem;
  }

  &__denomination {
    flex: 1;

    display: flex;
    align-items: center;
    gap: 1.2rem;
  }

  &__denomination-text {
    flex: 1;
    border: 1.5px solid var(--border);
    padding: 8px;
    text-align: center;
    border-radius: 6px;
    font-weight: 600;
    cursor: pointer;
    white-space: nowrap;
  }

  &__denomination--active &__denomination-text {
    border: 1.5px solid #659837;
  }

  &__indemnity {
    text-align: justify;
    font-weight: 600;
    background-color: var(--info-bg);
    color: $color-primary;
    padding: 2.4rem;
    border-radius: 6px;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    gap: 6px;

    & a {
      &:link,
      &:visited {
        color: $color-primary;
        font-weight: 700;
      }
    }
  }

  &__rab {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 2.4rem;
  }

  &__rab-div {
    background-color: var(--form-button-inactive-bg);
    padding: 0 16px;
    border-radius: 8px;
    height: 48px;
    display: flex;
    align-items: center;
  }

  &__giftcards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.2rem;

    @media (max-width: 450px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__giftcard {
    height: 15rem;
    background-color: var(--form-button-inactive-bg);
    cursor: pointer;
    border-radius: 6px;
    overflow: hidden;

    position: relative;

    & img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }

    &--add {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      color: var(--form-button-inactive-text-color);
      text-transform: uppercase;

      & ion-icon {
        display: block;
        width: 1.8rem;
        height: 1.8rem;
      }
    }
  }

  &__giftcard-action {
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 2.4rem;
    height: 2.4rem;
    background-color: #fa5252;
    border-radius: 3px;
    border: none;
    cursor: pointer;

    & ion-icon {
      color: #fff;
      display: block;
      width: 1.8rem;
      height: 1.8rem;
    }
  }
}

.giftcard-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.8rem;

  margin-top: 2.4rem;

  &--left {
    justify-content: flex-start;
  }

  & button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;

    padding: 1.8rem 3.2rem;
    border-radius: 4px;
    font-family: inherit;
    cursor: pointer;
    border: none;

    & ion-icon {
      display: block;
    }

    &:nth-child(1) {
      background-color: rgba($color-primary-darker, 0.2);
      color: $color-primary-darker;
    }

    &:nth-child(2) {
      background-color: var(--form-button-inactive-bg);
      color: var(--form-button-inactive-text-color);

      &.active {
        color: #fff;
        background-color: $color-primary;
      }
    }
  }
}
