.virtual-card {
  width: 60rem;
  max-width: 100%;
  margin: 4.8rem auto 9.6rem;
  border-radius: 6px;
  overflow: hidden;
  background-color: var(--header-bg);
  padding: 5.6rem 5.6rem 7.2rem;

  display: flex;
  flex-direction: column;

  @media (max-width: 450px) {
    padding: 3.2rem 3.2rem 5.6rem;
  }

  @media (max-width: 360px) {
    padding: 2.4rem;
  }

  &--md {
    width: 100%;

    @media (max-width: 1050px) {
      padding: 2.4rem;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 2.4rem;
  }

  &__heading {
    font-size: 2.8rem;
    font-weight: 600;
  }

  &__text {
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
  }

  &__back {
    cursor: pointer;

    ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  &__img {
    align-self: center;
    width: 35rem;
    max-width: 90%;

    margin-top: 7.2rem;
    margin-bottom: 2.4rem;
  }

  &__alert {
    align-self: center;

    display: flex;
    align-items: center;
    gap: 0.8rem;

    padding: 0.8rem;
    padding-right: 4.8rem;
    border-radius: 0.8rem;
    background-color: var(--chat-bg-1);

    color: $color-primary;
    font-size: 12px;
    // font-family: "Lato", sans-serif;
    font-weight: 400;

    margin-bottom: 3.2rem;

    @media (max-width: 450px) {
      padding-right: 0.8rem;
    }

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      flex-shrink: 0;
    }
  }

  &__body {
    margin-top: 3.2rem;
    margin-bottom: 4.8rem;

    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  &__account {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.6rem;

    padding: 1.6rem;
    border: 1px solid var(--border);
    border-radius: 0.8rem;
    background-color: var(--virtual-account-bg);
    cursor: pointer;
  }

  &__account-img {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
  }

  &__account-balance {
    padding: 0 1.2rem;
    border-radius: 4px;
    background-color: rgba($color: $color-success, $alpha: 0.2);
    color: $color-primary;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__account-caret {
    margin-left: auto;

    display: block;
    width: 1.8rem;
    height: 1.8rem;
  }

  &__user {
    padding: 4rem;
    background-color: var(--vcard-user-bg);

    display: flex;
    flex-direction: column;
    gap: 2.8rem;

    @media (max-width: 450px) {
      padding: 2.4rem;
    }
  }

  &__theme-block {
    background-color: var(--vcard-user-bg);

    padding: 1.6rem;

    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  &__theme-heading {
    opacity: 0.8;
    font-weight: 500;
    text-align: center;
  }

  &__themes {
    height: 5.6rem;

    display: flex;
    justify-content: center;
    gap: 0.8rem;
  }

  &__theme {
    flex: 0 0 8.5rem;

    position: relative;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.5);
      z-index: 2;

      opacity: 0;
      transition: all 0.3s;
      pointer-events: none;
    }

    &.active,
    &:hover {
      &::before {
        opacity: 1;
      }
    }

    & img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      z-index: 1;

      transition: all 0.3s;
    }

    &:hover img {
      transform: scale(1.1);
    }

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      color: #fff;

      position: relative;
      z-index: 3;

      transition: all 0.3s;

      opacity: 0;
      pointer-events: none;
    }

    &.active ion-icon {
      opacity: 1;
    }
  }

  &__message {
    padding: 0.8rem 1.6rem;
    display: flex;
    align-items: center;
    gap: 2.4rem;
    border-radius: 0.8rem;
    background-color: var(--body-bg);

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      color: $color-danger;
      flex-shrink: 0;
    }

    &--warning ion-icon {
      color: $color-warning;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
  }

  &__search-form {
    width: 40rem;
    height: 5.6rem;
    border-radius: 1.2rem;

    @media (max-width: 450px) {
      width: 100%;
    }
  }

  &__cards-block {
    margin-top: 3.2rem;

    padding-left: 4.8rem;

    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    @media (max-width: 1150px) {
      padding-left: 0;
    }
  }

  &__cards-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.8rem;

    @media (max-width: 450px) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__cards-heading {
    display: flex;
    align-items: center;
    gap: 1.6rem;

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  &__cards-button {
    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;

    border: none;
    color: $color-primary;
    height: 5.6rem;
    padding: 0 1.6rem;
    border-radius: 1.2rem;
    width: 32rem;
    font-family: inherit;
    font-size: inherit;
    font-weight: 600;
    cursor: pointer;
    background-color: var(--vcard-bg);

    @media (max-width: 500px) {
      width: auto;
      padding: 0 2.4rem;
    }

    @media (max-width: 450px) {
      order: -1;
    }

    & ion-icon {
      display: block;
      width: 2rem;
      height: 2rem;
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.4rem;

    @media (max-width: 650px) {
      grid-template-columns: 1fr;
    }
  }

  // &__info {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   gap: 1.4rem;
  // }

  // &__heading {
  //   font-size: 2rem;
  //   text-transform: uppercase;
  //   font-weight: 700;
  //   text-align: center;
  // }

  // &__text {
  //   font-size: 1.6rem;
  //   font-weight: 700;
  //   text-align: center;
  // }
}

.vau {
  display: flex;
  gap: 1.6rem;

  &__img {
    display: block;
    width: 4.8rem;
    height: 4.8rem;
  }

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__user {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    font-size: 1.5rem;
    font-weight: 600;

    & img {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  &__username {
    font-size: 1.5rem;
  }
}

.vau-verif {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__label {
    opacity: 0.8;
    font-weight: 500;
  }

  &__main {
    display: flex;
    gap: 1.6rem;

    padding-left: 0.8rem;

    & span {
      display: block;
      width: 2px;
      background-color: var(--border);
    }
  }
}

.virtual-card-modal {
  background-color: var(--header-bg);
  width: 50rem;
  max-width: 95%;
  max-height: 80dvh;
  border-radius: 4rem;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;

  padding: 8.4rem 7.4rem;

  &__link {
    color: $color-primary-darker;
    font-weight: 600;
  }
}

.vc-options {
  background-color: var(--options-bg);
  width: 55rem;
  max-width: 95%;
  max-height: 80dvh;
  border-radius: 1.6rem;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;

  padding: 5.6rem;

  @media (max-width: 450px) {
    padding: 3.6rem;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    margin-bottom: 2.4rem;
  }

  &__close {
    cursor: pointer;

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  &__heading {
    flex: 1;

    text-align: center;
    font-weight: 700;
    font-size: 2.4rem;
  }

  &__options {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  &__option {
    padding: 1.6rem 2.4rem;
    border-radius: 0.8rem;
    cursor: pointer;
    background-color: var(--option-bg);

    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  &__option-heading {
    font-size: 1.8rem;
    font-weight: 600;
  }

  &__option-description {
    opacity: 0.8;
    font-weight: 600;
  }
}

.vcard {
  position: relative;
  border-radius: 1.2rem;
  overflow: hidden;
  height: 28rem;
  color: #fff;

  &.blocked {
    opacity: 0.5;
  }

  &.frozen {
    opacity: 0.8;
  }

  @media (max-width: 950px) {
    height: 23rem;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  &__loader {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 7px;
    z-index: 999;
  }

  &__main {
    height: 100%;
    position: relative;
    background-color: transparent;

    padding: 2.4rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__chip {
    position: absolute;
    top: 50%;
    left: 2.4rem;
    transform: translateY(-60%);

    display: block;
    height: 4rem;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__top-left {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__status {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    font-weight: 600;

    padding: 4px 12px;
    border-radius: 4px;

    color: #fff;

    &::before {
      content: "";
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #fff;
    }

    &--active {
      background-color: $color-success;
    }

    &--frozen {
      background-color: #1480ea;
    }

    &--pending {
      background-color: $color-warning;
    }

    &--blocked {
      background-color: $color-danger;
    }
  }

  // &__label {
  // }

  &__app-logo {
    display: block;
    height: 4rem;
  }

  &__balance-block {
    display: flex;
    flex-direction: column;
  }

  &__balance-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;

    margin-bottom: -8px;

    & span {
      cursor: pointer;

      & ion-icon {
        display: block;
        width: 1.8rem;
        height: 1.8rem;
      }
    }
  }

  &__balance {
    text-align: center;
    font-size: 3.2rem;

    & span {
      &.hide {
        font-family: "Narnoor", serif !important;
      }
    }

    &--1 {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3px;

      font-size: 2.4rem;

      & span {
        font-weight: 700;
        font-size: 4.8rem;

        @media (max-width: 950px) {
          font-size: 3.6rem;
        }
      }
    }
  }

  &__footer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__card-info {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    text-transform: uppercase;

    & p {
      display: flex;
      align-items: center;
      gap: 1.8rem;
    }
  }

  &__card-logo {
    height: 4rem;
  }
}

.vcard-details {
  margin-top: 4rem;

  display: flex;
  gap: 6.4rem;

  @media (max-width: 1000px) {
    gap: 4.8rem;
  }

  @media (max-width: 950px) {
    flex-direction: column;
    gap: 0;
  }

  &__main {
    flex: 1;

    @media (max-width: 1000px) {
      flex: 0 0 40rem;
    }

    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  &__cards-block {
    position: relative;
  }

  &__cards {
    height: 28rem;
    overflow: hidden;

    position: relative;

    @media (max-width: 450px) {
      height: 24rem;
    }

    & > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      transition: all 0.3s;
    }
  }

  &__cards-control {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    background-color: var(--dark-primary-bg);
    filter: drop-shadow(0px 2px 8px rgba(30, 29, 29, 0.25));

    z-index: 99;

    & ion-icon {
      display: block;
      width: 2rem;
      height: 2rem;
      color: var(--light-primary-color);
    }

    &.previous {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
    }

    &.next {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
    }
  }

  &__actions {
    height: 7.5rem;
    background-color: var(--vcard-actions-bg);
    border-radius: 1rem;

    @media (max-width: 950px) {
      width: 45rem;
      max-width: 100%;
      align-self: center;
    }

    display: flex;
    align-items: center;
    gap: 2px;
  }

  &__action {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;

    font-weight: 600;
    color: $color-primary;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    & ion-icon {
      display: block;
      width: 2rem;
      height: 2rem;
    }
  }

  &__action-divider {
    display: block;
    width: 2px;
    height: 4rem;
    background-color: var(--border);
  }

  &__transactions {
    flex: 1;
  }

  &__card-info-overlay {
    display: none;

    @media (max-width: 950px) {
      display: block;
    }
  }

  &__card-info {
    padding: 2.4rem;
    border-radius: 0.8rem;
    background-color: var(--vcard-card-info-bg);

    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    @media (max-width: 950px) {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      z-index: 99999999;

      width: 50rem;
      max-width: calc(100vw - 4.8rem);

      transition: all 0.3s;

      &.open {
        transform: translate(-50%, -50%) scale(1);
      }
    }

    @media (max-width: 450px) {
      top: initial;
      left: 0;
      bottom: 0;
      width: 100%;
      transform: translate(0, 100%);
      max-width: 100%;

      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      padding-bottom: 5.6rem;

      &.open {
        transform: translate(0, 0);
      }
    }
  }

  &__card-data-grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__card-data {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    &--center {
      align-items: center;
    }

    & h3 {
      font-size: 1.4rem;
      opacity: 0.8;
    }

    & > div {
      font-weight: 600;

      display: flex;
      align-items: center;
      gap: 1.2rem;
    }
  }

  &__transactions {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  &__transactions-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.6rem;

    // @media (max-width: 420px) {
    //   flex-direction: column;
    //   gap: 1.2rem;
    // }
  }

  &__transactions-heading {
    font-size: 1.8rem;
    font-weight: 700;
  }

  &__transactions-filters {
    display: flex;
    align-items: center;
    gap: 2.4rem;
  }

  &__transactions-filter {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  &__transactions-main {
    flex: 1;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.vcard-transaction {
  padding: 0.8rem 1.6rem;
  border-radius: 0.8rem;
  background-color: var(--vcard-transaction-bg);

  display: flex;
  align-items: center;
  gap: 1.8rem;

  cursor: pointer;

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: stretch;
    gap: 4px;

    padding: 1.2rem 1.8rem;
  }

  &__desc {
    opacity: 0.8;
    flex: 1;
    font-size: 1.6rem;
    font-weight: 500;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;

    @media (max-width: 450px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      // & > *:last-child {
      //   order: -1;
      // }
    }
  }

  &__amount {
    // font-size: 1.3rem;
  }

  &__date-time {
    font-size: 1.25rem;
    opacity: 0.8rem;

    opacity: 0.8;

    display: flex;
    align-items: center;
    gap: 0.8rem;
  }
}

.vcard-create {
  &__header {
    padding: 2.4rem;
    background-color: var(--pay-bills-right-info-bg);

    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  &__heading {
    text-align: center;
    font-weight: 700;
    font-size: 1.4rem;
    color: var(--text-color-dark);
  }
}
