.user-blocked {
  position: absolute;
  top: 0;
  left: 0;

  width: 100dvw;
  height: 100dvh;

  background-size: cover;

  padding: 0 2.4rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2.4rem;

  &__img-block {
    text-align: center;
  }

  &__img {
    display: inline-block;
    height: 6.5rem;
    margin-bottom: 2.4rem;
  }

  &__main {
    height: 60dvh;
    width: 72rem;
    max-width: 100%;
    background-color: var(--header-bg);
    padding: 4.8rem 3.6rem;
    border-radius: 1.2rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__img {
    height: 12rem;
    margin-bottom: 3.6rem;
  }

  &__heading {
    font-weight: 700;
    font-size: 2.4rem;
    margin-bottom: 1.2rem;
  }

  &__text {
    text-align: center;
    margin-bottom: 3.6rem;
    max-width: 45rem;

    & span {
      color: #e53935;
      font-weight: 700;
    }
  }

  &__button {
    background-color: #e53935;
    color: #fff;
    width: 20rem;
    max-width: 100%;
    border-radius: 6px;
    cursor: pointer;
    font-family: inherit;
    padding: 1.2rem;
    border: none;
  }
}

// @media (max-width: 450px) {
//   .Main {
//     height: auto;
//     padding: 48px 24px;
//   }
// }
