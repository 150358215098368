.transaction-label {
  display: flex;
  align-items: center;
  gap: 1.2rem;

  &__icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  &--deposit &__icon-box {
    background-color: var(--transaction-label-bg-deposit);

    & ion-icon {
      color: #5db013;
      transform: rotate(45deg);
    }
  }

  &--withdraw &__icon-box {
    background-color: var(--transaction-label-bg-withdraw);

    & ion-icon {
      color: #f91b1b;
      transform: rotate(45deg);
    }
  }

  &--trade &__icon-box {
    background-color: var(--transaction-label-bg-trade);

    & ion-icon {
      color: #f9a21b;
    }
  }
}

.transaction-date {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 4px;

  & > p:first-child {
    text-transform: uppercase;
    font-size: 1.8rem;
    font-weight: 600;
  }

  & > p:last-child {
    font-size: 1.2rem;
  }
}

.transaction {
  background-color: var(--util-bg);
  padding: 1.6rem 2.4rem;
  cursor: pointer;
  border-radius: 0.8rem;

  display: flex;
  align-items: center;
  gap: 2.4rem;

  &:hover {
    // background-color: var(--table-hover);
  }

  &__time {
    flex: 0 0 3rem;
  }

  &__main {
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.8rem;

    @media (max-width: 500px) {
      flex-direction: column;
      align-items: stretch;
      gap: 1.2rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 4px;

    font-weight: 600;
  }

  &__text {
    font-size: 1.6rem;
  }

  &__status-amount {
    display: flex;
    align-items: center;
    gap: 2.4rem;

    @media (max-width: 500px) {
      & span:last-child {
        margin-left: auto;
      }
    }
  }
}

.transactions-flex {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  @media (min-width: 951px) {
    display: none;
  }
}

.transaction-mobile {
  padding: 1.2rem 1.8rem;
  background-color: var(--header-bg);
  border: 1px solid var(--border);
  border-radius: 1.2rem;

  display: flex;
  align-items: center;
  gap: 2.4rem;

  &__main {
    margin-right: auto;

    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__type {
    font-size: 1.6rem;
    font-weight: 600;
    display: none;

    @media (max-width: 450px) {
      display: block;
    }
  }

  &__text {
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 600;

    @media (max-width: 450px) {
      display: none;
    }
  }

  &__hash {
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 3px;
  }

  &__amount {
    text-transform: uppercase;
    font-weight: 600;
    white-space: nowrap;
  }

  &__date {
    text-transform: uppercase;
    white-space: nowrap;
  }
}

.transaction-limit {
  display: flex;
  flex-direction: column;
  gap: 3px;

  &__div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__span {
    display: flex;
    align-items: center;
    gap: 6px;

    color: $color-primary;
  }

  &__check {
    display: block;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 2px;
    // background-color: var(--form-button-inactive-bg);
    background-color: #d9d9d9;
    margin-top: -1px;

    &--primary {
      background-color: $color-primary;
    }
  }
}
