@import url("https://fonts.googleapis.com/css2?family=Karla:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Narnoor&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lily+Script+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Sora:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;

  @media (max-width: 750px) {
    font-size: 55%;
  }

  @media (max-width: 600px) {
    font-size: 50%;
  }

  @media (max-width: 360px) {
    font-size: 47%;
  }
}

body {
  box-sizing: border-box;

  max-width: 100dvw;
  overflow-x: hidden;

  font-size: 1.4rem;
  font-weight: 400;
  font-family: "Karla", sans-serif;
  color: var(--text-color);
  background-color: var(--body-bg);
  line-height: 1.5;

  padding-top: 7rem;
}

// copied code to prevent autofill
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--text-color) !important;
}

.bulk-otp {
  padding: 9.6rem 0;
}
