.status {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(30, 30, 30, 0.8);
  backdrop-filter: blur(3px);
  z-index: 9999999999;
  padding: 4.8rem;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;

  @media (max-width: 450px) {
    padding: 2.4rem;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__control {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 4.2rem;
    height: 4.2rem;
    border-radius: 50%;
    background-color: var(--header-bg);
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
    border: none;

    position: absolute;
    z-index: 99999;

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      color: var(--text-color);
    }

    &--prev {
      top: 50%;
      left: 4.8rem;
      transform: translateY(-50%);

      @media (max-width: 600px) {
        left: 2.4rem;
      }

      @media (max-width: 500px) {
        top: initial;
        transform: translateY(0);
        bottom: 4.8rem;
        left: 4.8rem;
      }
    }

    &--next {
      top: 50%;
      right: 4.8rem;
      transform: translateY(-50%);

      @media (max-width: 600px) {
        right: 2.4rem;
      }

      @media (max-width: 500px) {
        top: initial;
        transform: translateY(0);
        bottom: 4.8rem;
        right: 4.8rem;
      }
    }
  }

  &__header {
    width: 100rem;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 2.4rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__icon-box {
    cursor: pointer;

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      color: #fff;
    }
  }

  &__box {
    background-color: var(--header-bg);
    width: 45rem;
    max-width: 100%;
    margin: auto;
    height: 60rem;
    padding: 2.4rem;

    display: flex;
    flex-direction: column;
    gap: 2rem;

    display: none;
    opacity: 0;
    transform: scale(0);

    transition: transform 0.4s, opacity 0.2s 0.2s;

    @media (max-width: 450px) {
      width: 100%;
    }

    &--active {
      display: flex;
      opacity: 1;
      transform: scale(1);
    }
  }

  &__indicators {
    display: flex;
    gap: 6px;
  }

  &__indicator {
    flex: 1;
    height: 3px;
    background-color: #bdbdbd;
    border-radius: 10rem;
  }

  &__indicator-progress {
    background-color: $color-primary;
    height: 3px;
    width: 0%;
    border-radius: 10rem;
    transition: all 0.01s;

    &.done {
      width: 100%;
    }

    &.current {
      animation-name: statusMove;
      animation-fill-mode: both;
      animation-timing-function: linear;
      animation-duration: 5s;
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__playback {
    cursor: pointer;

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  &__content {
    flex: 1;

    display: flex;

    overflow: hidden;
  }

  &__text {
    height: 100%;
    width: 100%;

    background-color: var(--account-bg-green);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 3.6rem;
    font-size: 1.8rem;
  }

  &__loader {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__img {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}
