.app-brand {
  display: flex;
  align-items: center;

  & img {
    height: 4.5rem;
  }
}

.home-header {
  height: 7rem;
  z-index: 999999;
  background-color: var(--header-bg);

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  &__container {
    max-width: 140rem;
    margin: auto;
    padding: 0 2.4rem;
    height: 100%;

    display: flex;
    align-items: center;
    gap: 4.8rem;

    background-color: var(--header-bg);
    z-index: 999999;
  }
}

.navbar {
  margin-left: auto;

  display: flex;
  gap: 9.6rem;

  &__nav {
    list-style: none;

    display: flex;
    align-items: center;
    gap: 3.6rem;

    & li {
      @media (max-width: 750px) {
        width: 100%;
        display: flex;
      }
    }

    @media (max-width: 750px) {
      position: fixed;
      top: 6.5rem;
      left: 0;

      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 1.2rem;

      background-color: var(--header-bg);
      padding: 2rem 2.8rem;
      border-top: 5.5px solid var(--border);

      transition: all 0.4s;

      transform: translateY(-100%);
      z-index: -1;
    }
  }

  &--open &__nav {
    @media (max-width: 750px) {
      transform: translateY(0);
    }
  }

  &__nav-item-mobile {
    display: none;

    @media (max-width: 750px) {
      display: flex;
    }
  }

  &__link {
    &:link,
    &:visited {
      font-weight: 600;
      color: var(--text-color);
      text-decoration: none;

      transition: all 0.2s;
    }

    &:hover,
    &:active {
      color: $color-primary;
    }

    &--active {
      &:link,
      &:visited {
        color: $color-primary;
      }
    }

    @media (max-width: 750px) {
      &:link,
      &:visited {
        width: 100%;
        padding: 1.2rem 0;
      }
    }
  }

  &__btn-link {
    &:link,
    &:visited {
      display: inline-block;
      padding: 0.8rem 3.2rem;
      background-color: $color-primary;
      border-radius: 10rem;
      color: $color-white;
      font-weight: 500;
      text-decoration: none;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 1.8rem;
  }

  &__right &__link {
    @media (max-width: 750px) {
      display: none;
    }
  }

  &__btn {
    display: none;
    background-color: transparent;
    border: none;

    & ion-icon {
      display: block;
      width: 2.8rem;
      height: 2.8rem;
      color: var(--text-color);

      &:first-child {
        display: block;
      }

      &:last-child {
        display: none;
      }
    }

    @media (max-width: 750px) {
      display: flex;
    }
  }

  &--open &__btn ion-icon {
    &:first-child {
      display: none;
    }

    &:last-child {
      display: block;
    }
  }
}

.header-user-welcome {
  &:link,
  &:visited {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    text-decoration: none;
    color: var(--text-color);
    font-weight: 600;
  }

  &__img {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    object-fit: cover;
  }

  &__text {
    @media (max-width: 750px) {
      display: none;
    }
  }
}

.dashboard-header {
  height: 7rem;
  z-index: 999999;
  background-color: $color-primary-darker;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  &__container {
    max-width: 140rem;
    margin: auto;
    padding: 0 2.4rem;
    height: 100%;

    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 2.4rem;

    z-index: 999999;
    background-color: $color-primary-darker;
  }
}

.navbar2 {
  flex: 1;

  list-style: none;

  display: flex;
  align-items: stretch;
  gap: 1.2rem;

  @media (max-width: 1280px) {
    position: fixed;
    top: 7rem;
    left: 0;
    width: 100%;

    background-color: var(--header-bg);

    display: flex;
    flex-direction: column;
    gap: 0;

    transition: all 0.4s;

    transform: translateY(-100%);
    z-index: -1;
  }

  &--open & {
    @media (max-width: 1280px) {
      transform: translateY(0);
    }
  }

  &__item {
    display: flex;
    align-items: center;

    @media (max-width: 1280px) {
      width: 100%;
    }
  }

  &__link {
    &:link,
    &:visited {
      color: $color-white;
      padding: 0.7rem 1.4rem;
      border-radius: 6px;
      z-index: 99;
      text-decoration: none;

      white-space: nowrap;

      display: flex;
      align-items: center;
      gap: 6px;
    }

    &--active,
    &:hover,
    &:active {
      background-color: rgba(255, 255, 255, 0.06);
    }

    @media (max-width: 1280px) {
      &:link,
      &:visited {
        padding-top: 16px;
        padding-bottom: 16px;
        color: var(--text-color);
        background-color: var(--header-bg);
        border-left: 3px solid transparent;
        border-radius: 0;

        width: 100%;
      }

      &--active:link,
      &--active:visited,
      &:hover,
      &:active {
        background-color: var(--dashboard-header-submenu-hover);
        color: $color-primary-light;
        border-left: 3px solid $color-primary-light;
      }
    }
  }

  &__item-has-submenu {
    position: relative;

    @media (max-width: 1280px) {
      flex-direction: column;
    }
  }

  &__sub-menu {
    position: absolute;
    right: 0;
    top: 0;
    padding-top: 8.5rem;

    width: 20rem;

    display: none;

    @media (max-width: 1280px) {
      position: relative;

      padding: 1.2rem 0 1.2rem 2.4rem;
      width: 90%;
    }
  }

  &__item-has-submenu:hover &__sub-menu {
    display: block;
  }

  &__sub-menu-menu {
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);

    @media (max-width: 1280px) {
      border-radius: 0;
      box-shadow: none;
    }
  }

  &__sub-menu-link {
    &:link,
    &:visited {
      display: flex;
      align-items: center;
      gap: 8px;

      padding: 1.2rem;
      color: var(--text-color);
      background-color: var(--header-bg);
      border-left: 3px solid transparent;
      text-decoration: none;
    }

    &--active:link,
    &--active:visited,
    &:hover,
    &:active {
      background-color: var(--dashboard-header-submenu-hover);
      color: $color-primary-light;
      border-left: 3px solid $color-primary-light;
    }

    & ion-icon {
      display: block;
      width: 1.6rem;
      height: 1.6rem;
    }

    @media (max-width: 1280px) {
      &:link,
      &:visited {
        background: transparent;
        color: var(--text-color);
        text-align: left;
      }
    }
  }

  @media (max-width: 1280px) {
    &__sub-menu-item:not(:last-child) &__sub-menu-link {
      border-bottom: none;
    }
  }

  &__wallet-btns {
    margin-left: auto;

    display: flex;
    align-items: center;
    gap: 1.2rem;

    @media (max-width: 1280px) {
      margin-left: 0;
      padding: 12px;
      background-color: var(--dashboard-header-submenu-hover);

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 12px;
    }
  }

  &__wallet-btn {
    display: inline-block;
    padding: 1.3rem 1.6rem;
    border-radius: 6px;
    white-space: nowrap;
    text-decoration: none;
    font-family: inherit;
    border: none;
    line-height: 1;
    cursor: pointer;

    &:first-child {
      color: $color-white;
    }

    &:nth-child(2) {
      background-color: $color-white;
      color: $color-primary-darker;
    }

    &:last-child {
      border: 1px solid $color-white;
      background-color: transparent;
      color: $color-white;
    }

    @media (max-width: 1280px) {
      text-align: center;
      padding: 16px;

      &:first-child {
        grid-column: 1 / -1;
        color: $color-primary-light;
        font-size: 24px;
        padding: 0;
      }

      &:nth-child(2) {
        background-color: $color-primary-darker;
        color: $color-white;
        font-size: 1.6rem;
      }

      &:last-child {
        border: 1px solid $color-primary-darker;
        background-color: transparent;
        color: $color-primary-darker;
        font-size: 1.6rem;
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }

  &__btn {
    background-color: transparent;
    border: none;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    display: none;

    @media (max-width: 1280px) {
      display: flex;
    }

    & ion-icon {
      display: block;
      color: $color-white;
      width: 2.8rem;
      height: 2.8rem;

      &:first-child {
        display: block;
      }

      &:last-child {
        display: none;
      }
    }
  }

  &--open &__btn ion-icon {
    &:first-child {
      display: none;
    }

    &:last-child {
      display: block;
    }
  }
}

.header-notification {
  align-self: center;
  position: relative;
  cursor: pointer;

  & ion-icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    color: $color-white;
  }

  &__number {
    position: absolute;
    top: 0;
    right: 0;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 1.4rem;
    height: 1.4rem;

    border-radius: 50%;
    background-color: red;
    color: $color-white;
    font-size: 10px;

    transform: translate(50%, -50%);
  }
}
