.success-modal {
  background-color: var(--header-bg);
  width: 40rem;
  max-width: 95%;
  max-height: 80dvh;
  border-radius: 4px;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;

  padding: 4.8rem 2.4rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  &__close {
    cursor: pointer;

    position: absolute;
    top: 1.6rem;
    right: 1.6rem;

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  & h3 {
    font-size: 2.4rem;
    font-weight: 600;
    color: var(--text-color);
  }

  &__btns {
    margin-top: 2.4rem;

    display: flex;
    align-items: center;
    gap: 1.6rem;

    & button {
      flex: 1;
      padding: 0 4.8rem;
      height: 4.8rem;
    }
  }
}

.withdraw-type-modal {
  background-color: var(--body-bg);
  width: 50rem;
  max-width: 95%;
  border-radius: 2.4rem;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;

  padding: 7.2rem 4.8rem 4.8rem;

  display: flex;
  flex-direction: column;
  gap: 3.2rem;

  &--1 {
    max-height: 70dvh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__close {
    position: absolute;
    top: 3.2rem;
    left: 4.8rem;
    cursor: pointer;

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
  }

  &__heading {
    font-size: 2.2rem;
  }

  &__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    & label {
      padding: 1.6rem 2.4rem;
      background-color: var(--header-bg);
      border-radius: 6px;
      cursor: pointer;

      display: flex;
      align-items: center;
      gap: 1.2rem;

      & span {
        font-weight: 600;
      }
    }
  }

  &__button {
    width: 80%;
    align-self: center;

    flex-shrink: 0;
  }
}

.currency-modal {
  background-color: var(--body-bg);
  width: 40rem;
  max-width: 95%;
  height: 55rem;
  max-height: 80dvh;
  border-radius: 4px;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;

  padding: 2.4rem;

  display: flex;
  flex-direction: column;
  gap: 1.8rem;

  &--sm {
    height: auto;
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__close {
    cursor: pointer;

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  &__heading {
    flex: 1;

    text-align: center;
    font-size: 2rem;
    font-weight: 700;
  }

  &__info {
    font-size: 1.2rem;
    font-style: italic;
    text-align: center;
  }

  &__list {
    flex: 1;

    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    overflow-y: auto;

    padding-right: 7px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: var(--currencies-scrollbar-track-light);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--currencies-scrollbar-thumb);
    }
  }
}

.deposit-naira-modal {
  background-color: var(--body-bg);
  width: 45rem;
  max-width: 95%;
  max-height: 80dvh;
  border-radius: 4px;
  overflow: hidden;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;

  display: flex;
  flex-direction: column;
  gap: 1.8rem;

  padding: 2.4rem;
  color: $color-danger;

  &__heading {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    font-size: 2.2rem;
    text-transform: uppercase;
  }
}

.crypto-deposit-block {
  background-color: var(--currency-modal-bg);
  width: 45rem;
  max-width: 95%;
  margin: 4.8rem auto;
  border-radius: 4px;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  &__header {
    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;

    padding: 2.4rem 1.6rem;
    position: relative;
    background-color: var(--mt-giftcards-header);
    font-size: 2rem;
    font-weight: 700;

    & span {
      cursor: pointer;

      & ion-icon {
        display: block;
      }
    }
  }

  &__body {
    padding: 2.4rem;
    background-color: var(--header-bg);
  }
}

.deposit-modal {
  background-color: var(--body-bg);
  width: 45rem;
  max-width: 95%;
  max-height: 80dvh;
  border-radius: 4px;
  overflow: hidden;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;

  display: flex;
  flex-direction: column;

  & > * {
    font-size: 12.5px !important;
  }

  &__header {
    flex-shrink: 0;

    padding: 1.6rem;
    position: relative;
    background-color: var(--mt-giftcards-header);
  }

  &__close {
    position: absolute;
    top: 6px;
    right: 6px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 100%;
    background-color: rgba($color-danger, 0.2);
    cursor: pointer;

    & ion-icon {
      display: block;
      width: 1.4rem;
      height: 1.4rem;
      color: $color-danger;
    }
  }

  &__heading {
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
  }

  &__body {
    flex: 1;
    overflow-y: scroll;

    padding: 1.6rem;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: var(--currencies-scrollbar-track-light);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--currencies-scrollbar-thumb);
    }
  }
}

.withdrawal-type {
  background-color: var(--header-bg);
  width: 42rem;
  max-width: 95%;
  max-height: 80dvh;
  border-radius: 4px;
  overflow: hidden;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;

  padding: 2.4rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 2.4rem;
  }

  &__heading {
    font-size: 1.8rem;
    font-weight: 600;
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 1.8rem;
    height: 1.8rem;
    border-radius: 6px;
    background-color: rgba($color-danger, 0.2);
    color: $color-danger;
    cursor: pointer;

    & ion-icon {
      display: block;
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  &__methods {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }

  &__method {
    padding: 1.2rem 1.4rem;
    border-radius: 6px;
    cursor: pointer;
    background-color: var(--body-bg);

    display: flex;
    align-items: center;
    gap: 1.6rem;
  }

  &__method-icon-box {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
    background-color: rgba($color-success, 0.2);

    ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      color: $color-success;
    }
  }

  &__method-text {
    margin-right: auto;
  }

  &__method-icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
  }
}

.verify-account-modal {
  background-color: var(--body-bg);
  width: 45rem;
  max-width: 95%;
  margin: auto;
  overflow: hidden;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;

  padding: 2.4rem;
  border-radius: 0.8rem;

  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  &__close {
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;

    cursor: pointer;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    color: var(--text-color);
    font-weight: 600;
    font-size: 1.8rem;

    & ion-icon {
      display: block;
      color: #ffd600;
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    a {
      color: #448c04;
      text-decoration: underline;
    }
  }
}

.classic-modal {
  background-color: var(--body-bg);
  width: 45rem;
  max-width: 95%;
  margin: auto;
  overflow: hidden;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;

  border-radius: 3px;
  overflow: hidden;

  &--file-prev {
    padding: 1.6rem;
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
  }

  &__img {
    max-height: 30rem;
    object-fit: cover;
    border-radius: 3px;
  }

  &__previews {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 6px;
  }

  &__preview {
    display: block;
    width: 6rem;
    height: 6rem;
    border-radius: 6px;
    cursor: pointer;
    border: 2px solid var(--border);
    position: relative;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    &--active {
      border: 2px solid #55ab07;
    }

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__preview-action {
    position: absolute;
    top: 3px;
    right: 3px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 1.5rem;
    height: 1.5rem;
    background-color: #fa5252;
    border-radius: 50%;
    border: none;
    cursor: pointer;

    & ion-icon {
      color: #fff;
      display: block;
      width: 1.4rem;
      height: 1.4rem;
    }
  }

  &__header {
    padding: 1.2rem 1.8rem;
    border-bottom: 1px solid var(--border);

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__heading {
    text-transform: uppercase;
    font-size: 16px;
  }

  &__close {
    cursor: pointer;

    & ion-icon {
      display: block;
      width: 18px;
      height: 18px;
    }
  }

  &__body {
    padding: 18px;
  }
}

.notifications-block {
  position: fixed;
  top: 7rem;
  right: 0;

  height: 70dvh;
  width: 35rem;
  max-width: 100%;
  z-index: 99999999999;

  background-color: var(--header-bg);

  transform: translateX(100%);
  transition: all 0.3s;

  display: flex;
  flex-direction: column;

  &--show {
    transform: translateX(0);
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 1.8rem;

    padding: 1.2rem;

    border-bottom: 1px solid var(--border);
    font-weight: 500;
    font-size: 1.6rem;

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  &__header2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.8rem;
    padding: 1.2rem;

    border-bottom: 1px solid var(--border);
  }

  &__close {
    margin-left: auto;
    cursor: pointer;

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  &__notifications {
    flex: 1;

    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__info {
    padding: 1.2rem;
    text-align: center;
  }

  &__clear {
    padding: 1.2rem;
    text-align: center;
    border-top: 1px solid var(--border);
    cursor: pointer;

    &:hover {
      background-color: var(--currency-bg-2-hover);
    }
  }
}

.notification {
  display: flex;
  align-items: center;
  gap: 1.6rem;

  padding: 1.2rem;
  border-bottom: 1px solid var(--border);
  cursor: pointer;

  transition: all 0.1s;

  &:hover {
    background-color: var(--currency-bg-2-hover);
  }

  &__btn {
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    border: none;
    border-radius: 50%;
    background-color: $color-primary;
  }

  &__main {
    flex: 1;

    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__text {
    font-weight: 600;
  }

  &__dates {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.2rem;

    font-size: 1.2rem;
  }
}

.swap-modal {
  background-color: var(--body-bg);
  width: 45rem;
  max-width: 95%;
  margin: auto;
  overflow: hidden;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;

  padding: 2.4rem;
  border-radius: 1.2rem;

  display: flex;
  flex-direction: column;
  gap: 1.8rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__heading {
    font-size: 1.8rem;
    font-weight: 600;
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 100%;
    background-color: rgba($color-danger, 0.2);
    cursor: pointer;

    & ion-icon {
      display: block;
      width: 1.4rem;
      height: 1.4rem;
      color: $color-danger;
    }
  }

  &__main {
    background-color: var(--header-bg);
    padding: 2.4rem;
    border-radius: 1.2rem;

    display: flex;
    flex-direction: column;
    gap: 3.6rem;
  }

  &__swap-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__crypto {
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }

  &__crypto-img {
    display: block;
    width: 3.6rem;
    height: 3.6rem;
  }

  &__swap-icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
  }

  &__infos {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div:first-child {
      font-size: 1.2rem;
    }

    & > div:last-child {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 6px;

      font-size: 1.6rem;

      // & small {
      // }

      & span {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 3.2rem;
        height: 3.2rem;
        border: 1px solid $color-primary;
        border-radius: 50%;

        & ion-icon {
          display: block;
          width: 1.8rem;
          height: 1.8rem;
          color: $color-primary;
        }
      }
    }
  }
}

// crypto notice
.cnotice-modal {
  background-color: var(--cnotice-bg);
  width: 50rem;
  max-width: 95%;
  max-height: 80dvh;
  border-radius: 2.4rem;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;

  padding: 4rem 2.4rem;

  display: flex;
  flex-direction: column;
  gap: 4rem;

  &__close {
    cursor: pointer;

    position: absolute;
    top: 2.6rem;
    right: 2.4rem;

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  &__heading {
    text-align: center;
    font-size: 2.4rem;
    font-weight: 700;
    color: var(--tex-color-dark);
  }

  &__body {
    padding: 2.2rem 1.2rem;
    background-color: var(--cnotice-main-bg);
    border-radius: 0.8rem;

    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  &__info {
    padding: 1.2rem;
    background-color: var(--cnotice-info-bg);
    border-radius: 0.8rem;

    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  &__info-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;

    font-size: 1.6rem;
    color: var(--text-color-dark);
    font-weight: 700;

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      color: #e17613;
    }
  }

  &__info-text {
    color: var(--text-color-dark);
    text-align: center;
  }

  &__address-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;

    overflow: hidden;

    text-align: center;
  }

  &__address-label {
    color: #448c04;
    font-weight: 600;
  }

  &__address {
    overflow-x: scroll;
  }

  &__footer {
    text-align: center;

    & button {
      display: inline-flex;
      width: 15rem;
      max-width: 100%;
      height: 4.5rem;
    }
  }
}
