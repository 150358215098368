.pay-bills {
  display: flex;
  align-items: flex-start;
  gap: 3.6rem;

  &__main {
    flex: 1;

    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    min-width: 0;
  }

  &__filter {
    @media (min-width: 501px) {
      display: none !important;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__heading {
    font-size: 2.4rem;
    font-weight: 600;
  }

  &__description {
    font-weight: 500;
  }

  &__services {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2.4rem;

    @media (max-width: 1100px) {
      gap: 1.2rem;
    }

    @media (max-width: 650px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__service {
    padding: 2.4rem 2.4rem 0;
    height: 30rem;
    cursor: pointer;
    color: #fff;
    border-radius: 6px;

    display: flex;
    flex-direction: column;
    gap: 6px;

    @media (max-width: 650px) {
      height: 27rem;
      padding: 1.8rem 1.8rem 0;
    }

    &--1 {
      background-color: #5db013;
    }

    &--2 {
      background-color: #000000;
    }

    &--3 {
      background-color: #1ba6f9;
    }

    &--4 {
      background-color: #ffaf34;
    }
  }

  &__service-img-box {
    display: flex;
    justify-content: center;
    margin-top: auto;

    & img {
      display: block;
      width: 90%;

      @media (max-width: 650px) {
        width: 50%;
      }

      @media (max-width: 450px) {
        width: 80%;
      }
    }
  }

  &__service-heading {
    font-size: 1.6rem;
    font-weight: 600;
  }

  &__service-text {
    width: 90%;
  }

  &__transactions {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  &__transactions-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.6rem;
  }

  &__infos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.2rem;

    @media (max-width: 650px) {
      display: flex;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__info {
    padding: 3.2rem;
    border-radius: 5px;

    position: relative;

    @media (max-width: 700px) {
      padding: 2.4rem;
    }

    @media (max-width: 650px) {
      flex: 0 0 35rem;
    }

    &--1 {
      background-color: var(--header-bg);
    }

    &--2 {
      background-color: var(--pay-bills-info-bg-2);
    }

    & img {
      display: block;
      height: 6rem;

      position: absolute;
      top: 1.2rem;
      right: 1.2rem;
    }
  }

  &__info-main {
    width: 85%;
  }

  &__info-heading {
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 1.2rem;
  }

  &__info-text {
    margin-bottom: 2.4rem;
  }

  &__info-link {
    &:link,
    &:visited {
      display: flex;
      align-items: flex-end;
      gap: 1.6rem;
      color: $color-primary;
      text-decoration: none;
    }

    & span {
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: currentColor;
      }
    }

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  &__right {
    flex: 0 0 30rem;

    background-color: var(--header-bg);
    border-radius: 6px;
    padding: 1.2rem;

    @media (max-width: 1100px) {
      flex: 0 0 25rem;
    }

    @media (max-width: 1000px) {
      display: none;
    }
  }

  &__wallet-block {
    padding: 2.4rem 3.2rem;
    background-color: var(--pay-bills-right-header-bg);
    font-weight: 600;

    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    margin-bottom: 2.4rem;

    @media (max-width: 1100px) {
      padding: 2.4rem;
    }
  }

  &__wallet-label {
    font-size: 1.6rem;
  }

  &__wallet-balance {
    font-size: 3.6rem;
  }

  &__right-info {
    padding: 3.6rem 2.4rem;
    background-color: var(--pay-bills-right-info-bg);

    @media (max-width: 1100px) {
      padding: 2.4rem;
    }
  }

  &__right-info-heading {
    font-size: 2rem;
    margin-bottom: 1.2rem;
  }

  &__right-info-text {
    font-size: 1.2rem;
  }

  &__right-info-img-block {
    text-align: right;

    & img {
      display: inline-block;
      width: 70%;
    }
  }
}

.pay-bills-block {
  width: 45rem;
  max-width: 95%;
  margin: 4.8rem auto 9.6rem;
  padding: 3.2rem;
  background-color: var(--header-bg);
  border-radius: 1.2rem;

  &--fh {
    min-height: 45rem;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    margin-bottom: 3.2rem;

    & img {
      display: block;
      width: 3.6rem;
      height: 3.6rem;
    }
  }

  &__back {
    cursor: pointer;

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      color: $color-primary;
    }
  }

  &__header-2 {
    margin-bottom: 3.6rem;
  }

  &__heading {
    font-weight: 700;
    font-size: 1.8rem;
  }

  &__heading-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;

    font-size: 2rem;
    font-weight: 700;

    & span {
      cursor: pointer;

      & ion-icon {
        display: block;
      }
    }
  }

  &__subheading {
    text-align: center;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
  }

  &__infos {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    margin-bottom: 3.6rem;
  }

  &__info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4.8rem;

    & p:first-child {
      text-align: right;
    }
  }

  &__success-heading {
    font-size: 1.8rem;
    color: $color-primary;
    font-weight: 700;
    text-align: center;

    margin-bottom: 12px;
  }

  &__success-text {
    text-align: center;

    & span {
      font-weight: 500;
    }
  }

  &__services {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.8rem;
  }

  &__service {
    height: 8rem;
    background-color: #f5f5f5;
    border-radius: 1.2rem;
    cursor: pointer;
    padding: 0.8rem;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    & img {
      display: block;
      height: 100%;
      max-width: 100%;
      object-fit: contain;
      border-radius: 0.8rem;
    }

    &--active {
      background-color: none;
      border: 1px solid $color-primary;
    }
  }

  &__service-img {
    display: block;
    width: 8rem;
    height: 8rem;
  }
}

.bill-service {
  padding: 2.4rem;
  height: 30rem;
  cursor: pointer;
  color: #fff;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  gap: 6px;

  position: relative;
  overflow: hidden;

  &--loader {
    background-color: var(--util-bg);
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    z-index: -1;
  }

  &__heading {
    font-size: 1.6rem;
    font-weight: 600;
    // text-transform: capitalize;
  }

  &__text {
    width: 90%;
  }
}
