.account-pg {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  &__header {
    display: flex;
    align-items: center;
    gap: 1.6rem;

    font-size: 1.8rem;
    color: var(--text-color);

    padding-bottom: 1.8rem;
    border-bottom: 1px solid var(--border);

    @media (min-width: 701px) {
      display: none;
    }

    & span {
      cursor: pointer;
    }

    & ion-icon {
      display: block;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }
}

.account-user {
  padding: 2.4rem;
  background-color: var(--account-bg-green);

  display: flex;
  align-items: center;
  gap: 1.8rem;

  &__img-block {
    width: 4rem;
    height: 4rem;
    border: 3px solid $color-primary;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      display: block;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
    }
  }

  &__name {
    font-size: 1.6rem;
    font-weight: 700;
  }

  &__username {
  }

  &__verif {
    display: inline-block;
    margin-left: auto;
    background-color: #fff;
    padding: 0.8rem 2.4rem;
    font-weight: 500;
    border-radius: 10rem;
    border: none;
    font-family: inherit;
  }

  &__main {
  }
}

.account-menu {
  display: flex;
  align-items: center;
  gap: 3.2rem;
  justify-content: space-around;

  list-style: none;
  border-bottom: 1px solid var(--border);
  overflow-x: scroll;
  overflow-y: hidden;

  @media (max-width: 700px) {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__link {
    &:link,
    &:visited {
      display: flex;
      align-items: center;
      gap: 6px;

      padding: 8px 12px;

      border-bottom: 3px solid transparent;
      white-space: nowrap;
      text-decoration: none;
      color: var(--text-color);

      transform: translateY(1.5px);
    }

    & ion-icon {
      color: #bdbdbd;
    }

    &:hover,
    &:active {
      color: $color-primary-light;
      border-bottom: 3px solid $color-primary-light;

      & ion-icon {
        color: $color-primary-light;
      }
    }

    &--active {
      &:link,
      &:visited {
        color: $color-primary-light;
        border-bottom: 3px solid $color-primary-light;

        & ion-icon {
          color: $color-primary-light;
        }
      }
    }
  }
}

.account-mmenu {
  display: flex;
  flex-direction: column;

  @media (min-width: 701px) {
    display: none;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 1.8rem;

    padding: 1.8rem;
  }

  &__icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    color: $color-primary;
    visibility: visible;
  }

  &__main {
    flex: 1;
  }

  &__heading {
    font-size: 1.8rem;
    font-weight: 700;
  }

  &__text {
  }
}

.action-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.4rem;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

.action-card {
  display: flex;
  align-items: center;
  gap: 2.4rem;

  padding: 1.2rem;
  border: 1px solid var(--border);
  border-radius: 6px;

  cursor: pointer;

  &:hover {
    background-color: var(--util-bg);
  }

  &__info {
    flex: 1;
  }

  &__heading {
    font-size: 1.8rem;
    font-weight: 700;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
    background-color: $color-primary-light;
    color: $color-white;
    border: none;

    &--warning {
      background-color: $color-warning;
    }

    & ion-icon {
      display: block;
      width: 2rem;
      height: 2rem;
    }
  }
}

.account-alert {
  padding: 1.2rem;
  background-color: var(--account-bg-green);

  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.account-tiers {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.account-tier {
  display: flex;
  gap: 2.4rem;

  color: var(--text-color);
  opacity: 0.5;

  max-width: 50rem;

  &--active {
    opacity: 1;
  }

  &__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
  }

  &__btn {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    background-color: rgba($color-primary, 0.5);
    border: none;
    color: $color-white;

    position: relative;
  }

  &--active &__btn {
    background-color: $color-primary;
  }

  &--completed &__btn::after {
    content: "\2713";
  }

  &__line {
    flex: 1;
    width: 3px;
  }

  &--active &__line::after {
    content: "";
    display: block;
    background-color: $color-primary;
    color: $color-white;
    height: 50%;
    width: 3px;
  }

  &--completed &__line:after {
    height: 100%;
  }

  &__main {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.6rem;
  }

  &__title {
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 6px;

    margin-bottom: -1.2rem;
  }
}

.account-form {
  max-width: 60rem;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.4rem;

  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }

  &__item-full {
    grid-column: 1 / -1;
  }
}

.account-banks {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.4rem;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

.account-bank {
  border-radius: 6px;
  overflow: hidden;
  padding: 2.4rem;
  background-color: var(--account-bg-yellow);

  display: flex;
  align-items: center;
  gap: 1.8rem;

  &__icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
  }

  &__name {
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 4px;
  }

  &__account-name-number {
    display: flex;
    align-items: center;
    gap: 2.4rem;
  }
}

.account-add-bank {
  padding: 4.8rem;
  border: 2px solid var(--border);
  border-radius: 6px;
  color: $color-primary;
  cursor: pointer;
  transition: all 0.3s;

  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;

  & ion-icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
  }

  &:hover {
    background-color: $color-primary;
    color: #fff;
    border: 2px solid $color-primary;
  }
}

.account-section {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  &__header {
    padding-left: 2.4rem;
  }

  &__main {
    background-color: var(--header-bg);
    padding: 2.4rem;
    border-radius: 0.8rem;

    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  &__menu {
    list-style: none;

    display: flex;
    align-items: center;
    gap: 2.4rem;
  }

  &__menu-item {
    padding: 0 2.4rem 1.2rem;
    border-bottom: 3px solid transparent;
    cursor: pointer;

    &--active,
    &:hover {
      border-bottom: 3px solid var(--text-color);
    }
  }

  &__form {
    max-width: 40rem;

    display: flex;
    flex-direction: column;
    gap: 1.8rem;
  }

  &__block {
    padding: 1.2rem 2.4rem;
    border: 1px solid var(--border);
    border-radius: 6px;

    display: flex;
    flex-direction: column;
    gap: 6px;

    & p:first-child {
      font-size: 1.3rem;
      text-transform: uppercase;
    }

    & p:last-child {
      font-weight: 600;
      word-wrap: break-word;
    }
  }

  &__setting {
    display: flex;
    align-items: center;
    gap: 1.8rem;

    font-weight: 700;

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      color: $color-primary;
    }

    & input {
      margin-left: auto;
    }
  }
}

.account-earning {
  max-width: 25rem;
  padding: 2.4rem;
  border-radius: 6px;

  background-color: var(--account-bg-green);

  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  & p:first-child {
    font-size: 1.3rem;
    text-transform: uppercase;
  }

  & p:last-child {
    font-size: 1.8rem;
    font-weight: 800;
  }
}

.account-show-desktop {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  @media (max-width: 700px) {
    display: none;
  }
}
