.breadcrumb {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;

  &__item {
    &::after {
      content: ">";
      display: inline-block;
      margin-left: 1.2rem;
      color: #bdbdbd;
    }

    &--active::after {
      display: none;
    }
  }

  &__link {
    &:link,
    &:visited {
      text-decoration: none;
      color: #bdbdbd;
      font-size: 1.6rem;
    }
  }

  &__item--active &__link {
    &:link,
    &:visited {
      color: #448c04;
      font-weight: 600;
    }
  }
}
