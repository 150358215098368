.orders-transactions {
  &__header {
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
  }

  &__heading {
    font-size: 2rem;
    font-weight: 600;
  }

  &__header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__header-right {
    flex: 0 0 25rem;
  }

  &__filter {
    @media (min-width: 901px) {
      display: none !important;
    }

    & span {
      @media (max-width: 360px) {
        display: none !important;
      }
    }
  }
}
