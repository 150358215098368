.naira-block {
  width: 45rem;
  max-width: 100%;
  margin: 4.8rem auto 9.6rem;
  border-radius: 6px;
  overflow: hidden;

  background-color: var(--header-bg);
  padding: 3.6rem 2.4rem;

  &--final {
    padding: 4.8rem;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 3px;

    margin-bottom: 2.4rem;
  }

  &__heading {
    font-size: 2.2rem;
    font-weight: 600;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;

    & span {
      cursor: pointer;

      & ion-icon {
        display: block;
        width: 2rem;
        height: 2rem;
      }
    }
  }

  &__img {
    display: inline-block;
    height: 12rem;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
  }

  &__min-max {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__info-card {
    background-color: var(--info-bg);
    padding: 1.2rem;
    text-align: justify;
    color: $color-primary;

    &--2 {
      text-align: center;
      font-size: 1.3rem;
      border-radius: 6px;
    }
  }

  &__copiable {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.deposit-method {
  display: flex;
  gap: 1.6rem;
  cursor: pointer;

  &__img {
    display: block;
    width: 4.2rem;
    height: 4.2rem;

    margin-top: 6px;
  }

  &__heading {
    font-size: 1.8rem;
    font-weight: 600;
  }

  &__fee,
  &__label {
    font-weight: 400;
  }

  &__icon-box {
    margin-left: auto;

    padding-top: 6px;

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
    }
  }
}
