.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.2rem;

  & button {
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 4px;
    border: 1px solid $color-primary-darker;
    color: $color-primary-darker;
    font-size: 1.8rem;
    line-height: 0;
    cursor: pointer;
    background-color: transparent;
    transition: all 0.2s;
    font-family: inherit;

    display: flex;
    align-items: center;
    justify-content: center;

    &.active,
    &:hover {
      background-color: $color-primary-darker;
      color: #fff;
    }

    // & ion-icon {
    //   display: block;
    //   width: 1.8rem;
    //   height: 1.8rem;
    // }
  }
}

.pagination-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;

  &__text {
    cursor: pointer;
    color: var(--text-color);
    font-weight: 500;
  }

  &__buttons {
    display: flex;
    // align-items: flex-end;
    align-items: center;
    // gap: 0.8rem;
    gap: 2.4rem;

    & ion-icon {
      display: block;
      width: 1.6rem;
      color: #1f1f1f;
    }
  }

  &__button {
    padding: 0.8rem 1.6rem;
    border: 1px solid var(--border);
    color: inherit;
    font-weight: 500;
    font-family: inherit;
    background-color: transparent;
    border-radius: 4px;
    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 0.8rem;

    & ion-icon {
      display: block;
      color: inherit;
    }

    transition: all 0.3s;

    @media (max-width: 450px) {
      font-size: 1.2rem;
      padding: 6px 12px;
    }

    &.active,
    &:hover {
      background-color: $color-primary;
      border: 1px solid $color-primary;
      color: #fff;
    }

    &:disabled {
      opacity: 0.4;
      pointer-events: none;

      background-color: transparent !important;
      border: 1px solid var(--border) !important;
      color: inherit;
    }
  }
}
