.wallet-box {
  width: 100%;
  min-height: 32rem;
  border-radius: 2.4rem;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.4rem;

  padding: 0 9.6rem;
  color: $color-white;

  position: relative;

  @media (max-width: 950px) {
    padding: 0 6.4rem;
  }

  @media (max-width: 850px) {
    padding: 0 4.8rem;
  }

  @media (max-width: 650px) {
    padding: 0 3.6rem;
    min-height: 28rem;
  }

  @media (max-width: 580px) {
    min-height: initial;
  }

  @media (max-width: 580px) {
    flex-direction: column;
    justify-content: initial;
    // padding-top: 6.4rem;
    // padding-bottom: 2.4rem;
    // padding-left: 2.4rem;
    // padding-right: 2.4rem;

    padding: 3.2rem;
    border-radius: 3.6rem;
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    object-position: left;
    z-index: -1;

    // &--desktop {
    //   @media (max-width: 650px) {
    //     display: none !important;
    //   }
    // }

    // &--mobile {
    //   @media (min-width: 650px) {
    //     display: none !important;
    //   }
    // }
  }

  &__main {
    &--primary {
      color: $color-primary;
    }

    @media (max-width: 580px) {
      align-self: stretch;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    padding-left: 4.8rem;

    font-size: 1.8rem;

    margin-bottom: 1.2rem;

    @media (max-width: 580px) {
      justify-content: center;
      padding-left: 0;
      font-size: 2.4rem;
    }

    @media (max-width: 400px) {
      font-size: 1.6rem;
    }
  }

  &__show-hide {
    cursor: pointer;

    & ion-icon {
      display: block;
    }
  }

  &__balance-block {
    display: flex;
    flex-direction: column;
  }

  &__balance-toggle-wrapper {
    width: 65px;
    align-self: flex-start;

    display: inline-block;

    position: relative;

    @media (max-width: 580px) {
      align-self: center;
    }
  }

  &__balance-toggle {
    display: inline-flex;
    align-items: center;
    gap: 1.2rem;

    cursor: pointer;
    line-height: 0;

    padding: 0.6rem;
    border-radius: 0.4rem;

    margin-bottom: 0.8rem;

    & ion-icon {
      display: block;
    }

    &:hover {
      background-color: rgba(244, 250, 239, 0.13);
    }

    @media (max-width: 600px) {
      justify-content: center;
    }
  }

  &__balance-options {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(100%);
    z-index: 999;
    background-color: #fff;
    border-radius: 0.4rem;
    overflow: hidden;

    opacity: 0;
    pointer-events: none;

    transition: all 0.2s;

    &--open {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__balance-option {
    padding: 0.6rem;
    cursor: pointer;
    color: var(--text-color);
    background-color: var(--header-bg);
    text-align: center;

    &.active,
    &:hover {
      background-color: var(--dashboard-header-submenu-hover);
      color: $color-primary-light;
    }
  }

  &__balance {
    font-size: 6.4rem;
    font-weight: 600;
    cursor: pointer;

    line-height: 1;

    @media (max-width: 1000px) {
      font-size: 4.8rem;
    }

    @media (max-width: 580px) {
      text-align: center;
      font-size: 5.6rem;
    }

    @media (max-width: 400px) {
      font-size: 4.8rem;
    }
  }

  &__pts {
    display: inline-block;
    padding: 8px 2.4rem;
    background-color: rgba(244, 250, 239, 0.13);
    border: 0.4px solid $color-white;
    border-radius: 10rem;
    text-align: center;
    font-size: 1.8rem;
    cursor: pointer;

    margin-left: auto;

    display: none;

    @media (max-width: 580px) {
      display: block;
    }

    @media (max-width: 400px) {
      font-size: 1.4rem;
      padding: 0.8rem 1.6rem;
    }
  }

  &__points {
    display: inline-block;
    width: 18rem;
    background-color: rgba(244, 250, 239, 0.13);
    border: 0.4px solid $color-white;
    border-radius: 10rem;
    text-align: center;
    font-size: 1.8rem;
    padding: 8px 0;
    margin-top: 1.8rem;
    cursor: pointer;

    margin-left: 3.6rem;

    @media (max-width: 580px) {
      // position: absolute;
      // top: 1.2rem;
      // right: 1.2rem;
      // margin: 0;

      // width: auto;
      // padding: 8px 2.4rem;
      // font-size: inherit;

      display: none;
    }
  }

  &__points-2 {
    display: inline-block;
    background-color: #fff;
    border: 0.4px solid $color-primary;
    color: $color-primary;
    border-radius: 10rem;
    text-align: center;
    font-size: 2.4rem;
    font-weight: 500;
    padding: 1.2rem 4.8rem;
    margin-top: 1.8rem;
    cursor: pointer;
    font-family: inherit;
  }

  &__actions {
    display: flex;
    gap: 6px;

    @media (max-width: 580px) {
      display: none;
    }
  }
}

.wallet-box-action {
  width: 12rem;
  height: 12rem;
  border-radius: 6px;
  color: $color-white;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media (max-width: 1000px) {
    height: 10rem;
    width: 10rem;
  }

  &:hover {
    background-color: rgba(232, 252, 216, 0.07);
  }

  &--active {
    border: 1px solid $color-white;
    color: var(--wallet-card-action-active-color);
  }

  &__icon-box {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
    background-color: $color-white;
  }

  &--active &__icon-box {
    background-color: var(--wallet-card-action-active-icon-bg);
  }

  &__icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    color: $color-primary;
  }

  &--active &__icon {
    color: var(--wallet-card-action-active-icon-color);
  }
}

.assets-block {
  &__header {
    display: flex;
    align-items: center;
    gap: 1.8rem;

    margin-bottom: 2.4rem;

    @media (max-width: 450px) {
      flex-direction: column;
      align-items: flex-end;
    }
  }

  &__search-form {
    width: 30rem;

    @media (max-width: 450px) {
      width: 100%;
    }
  }

  &__hide {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    font-size: 1.8rem;

    @media (max-width: 450px) {
      order: -1;
    }
  }

  &__assets {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
}

.asset {
  cursor: pointer;
  padding: 1.2rem 0;
  padding-left: 10rem;
  background-color: var(--util-bg);
  border: 1px solid var(--util-border);
  font-size: 1.6rem;

  @media (max-width: 1000px) {
    padding: 1.2rem 3.6rem;
  }

  @media (max-width: 500px) {
    padding: 1.2rem 1.8rem;
  }

  @media (max-width: 550px) {
    display: none;
  }

  &__main {
    max-width: 900px;
    margin-right: auto;

    display: grid;
    grid-template-columns: 40fr 20fr 20fr 20fr;
    align-items: center;
    gap: 4.8rem;

    @media (max-width: 950px) {
      gap: 2.4rem;
    }
  }

  &__currency {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    white-space: nowrap;
  }

  &__currency-img {
    width: 3.6rem;
    height: 3.6rem;
    display: block;
  }

  &__currency-name {
    font-weight: 600;
  }

  &__value1 {
    text-align: right;
  }

  &__value-change {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.8rem;
  }
}

.asset-mobile {
  padding: 1.2rem 1.8rem;
  background-color: var(--header-bg);
  border: 1px solid var(--border);
  border-radius: 1.2rem;

  display: flex;
  align-items: center;
  gap: 2.4rem;

  @media (min-width: 550px) {
    display: none;
  }

  &__wallet {
    margin-right: auto;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
  }

  &__currency-img {
    width: 4.2rem;
    height: 4.2rem;
    display: block;
  }

  &__wallet-symbol {
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 600;
  }

  &__wallet-name {
    font-weight: 300;
  }

  &__wallet-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 3px;
  }

  &__balance {
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 600;
  }

  &__balance-usd {
    text-transform: uppercase;
  }
}

.wallet-giftcards {
  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2.4rem;

    @media (max-width: 850px) {
      grid-template-columns: repeat(3, 1fr);
    }

    // @media (max-width: 550px) {
    //   grid-template-columns: repeat(2, 1fr);
    // }

    @media (max-width: 360px) {
      gap: 1.2rem;
    }
  }
}

.tab-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.6rem;

  &__action {
    cursor: pointer;
    width: 12rem;
    height: 12rem;
    border: 1px solid var(--border);
    border-radius: 4px;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;

    &--active {
      background-color: rgba($color: $color-primary, $alpha: 0.1);
      border: 1px solid $color-primary;
    }
  }

  &__action-img {
    display: block;
    width: 24px;
    height: 24px;
  }
}

.wallet-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;

  &__heading {
    font-size: 2rem;
    font-weight: 600;
  }

  &__text {
    font-size: 1.6rem;
  }
}

.wallet-actions-mobile {
  display: flex;
  padding: 0 3.6rem;

  display: none;

  @media (max-width: 580px) {
    display: flex;
  }

  &__main {
    flex: 1;

    display: flex;
    justify-content: center;
    gap: 2.4rem;

    padding: 1.8rem 2.4rem;
    border-bottom-left-radius: 2.4rem;
    border-bottom-right-radius: 2.4rem;
    background-color: var(--wallet-actions-mobile-bg);
  }

  &__divider {
    align-self: center;
    height: 3.6rem;
    width: 1px;
    background-color: #98ad85;
  }
}

.wallet-action-mobile {
  color: $color-white;
  cursor: pointer;
  font-size: 1.2rem;
  color: var(--text-color-dark);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;

  &__icon-box {
    padding: 4px 1.2rem;
    border-radius: 20rem;
  }

  &:hover &__icon-box,
  &--active &__icon-box {
    background-color: rgba($color: #397305, $alpha: 0.2);
  }

  &__icon {
    display: block;
    height: 2.4rem;
  }
}
