.select-component {
  background-color: var(--header-bg);
  width: 35rem;
  height: 45rem;
  max-width: 100%;
  border-radius: 1.2rem;

  padding: 1.2rem 0 2.4rem;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;

  display: flex;
  flex-direction: column;

  &__header {
    text-align: center;

    margin-bottom: 2rem;

    & div {
      display: inline-block;
      width: 40%;
      border-radius: 10rem;
      height: 4px;
      background-color: rgba(28, 127, 214, 0.1);
    }
  }

  &__heading {
    font-size: 1.8rem;
    font-weight: 500;
    padding: 0 1.6rem;

    margin-bottom: 1.6rem;
  }

  &__search {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    height: 3.6rem;
    border: 1px solid var(--border);
    margin: 0 1.6rem;
    padding: 0 1.2rem;

    margin-bottom: 1.8rem;

    & input {
      border: none;
      outline: none;
      background-color: transparent;
      min-width: 0;
      flex: 1;
      font-family: inherit;

      background-color: transparent;
      color: var(--text-color);

      //   &::placeholder {
      //     color: var(--text-color-faint);
      //   }
    }

    & ion-icon {
      display: block;
    }
  }

  &__options {
    flex: 1;

    display: flex;
    flex-direction: column;
    overflow-y: auto;

    margin: 0 1.6rem;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: var(--currencies-scrollbar-track);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--currencies-scrollbar-thumb);
    }
  }

  &__option {
    padding: 1.6rem 0.8rem;
    border-bottom: 1px solid var(--bg-faint);
    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 1.2rem;

    &:hover {
      background-color: var(--bg-faint);
    }
  }

  &__img {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
  }

  &__radio {
    margin-left: auto;

    appearance: none;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 1.8rem;
    height: 1.8rem;
    border: 1px solid var(--border);
    border-radius: 50%;
    cursor: pointer;

    &::after {
      content: "";
      display: block;
      width: 1rem;
      height: 1rem;
      background-color: $color-primary;
      border-radius: 50%;
      display: none;
    }

    &:checked {
      border: 1px solid $color-primary;
    }

    &:checked::after {
      display: block;
    }
  }
}
