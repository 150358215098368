.imgs-preview {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(3px);
  z-index: 999999999;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  &__main {
    height: calc(100dvh - 10rem);

    padding: 2.4rem 3.6rem;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__control {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
    border: none;

    position: absolute;
    z-index: 9999999999999;

    &--prev {
      top: 50%;
      left: 2.4rem;
      transform: translateY(-50%);

      @media (max-width: 650px) {
        top: initial;
        transform: translateY(0);
        bottom: 4.8rem;
        left: 4.8rem;
      }
    }

    &--next {
      top: 50%;
      right: 2.4rem;
      transform: translateY(-50%);

      @media (max-width: 650px) {
        top: initial;
        transform: translateY(0);
        bottom: 4.8rem;
        right: 4.8rem;
      }
    }

    & ion-icon {
      display: block;
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  &__close {
    top: 2.4rem;
    right: 2.4rem;
  }

  &__img {
    max-width: 90%;
    max-height: 80%;
  }

  &__previews {
    height: 10rem;

    display: flex;
    align-items: center;
    gap: 6px;

    border-top: 1px solid var(--border);
    padding: 0 2.4rem;

    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__preview {
    flex-shrink: 0;

    display: block;
    width: 7rem;
    height: 7rem;
    border-radius: 6px;
    cursor: pointer;
    border: 4px solid var(--border);
    overflow: hidden;

    transition: all 0.3s;

    &--active {
      border: 4px solid #55ab07;
      width: 60px;
      height: 60px;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

// @media (max-width: 450px) {
//   .PreviewImgsMain {
//     padding: 12px;
//   }

//   .PreviewImg {
//     max-width: 100%;
//   }

//   .StatusControlPrevious {
//     top: initial;
//     bottom: 24px;
//     left: 24px;
//     transform: translate(0);
//   }

//   .StatusControlNext {
//     top: initial;
//     bottom: 24px;
//     right: 24px;
//     transform: translate(0);
//   }
// }

// @media (max-width: 450px) {
//   .StatusControl {
//     width: 24px;
//     height: 24px;
//   }

//   .StatusControl ion-icon {
//     width: 14px;
//     height: 14px;
//   }

//   .ClosePreview {
//     top: 12px;
//     right: 12px;
//   }

//   .StatusControlPrevious {
//     bottom: 12px;
//     left: 12px;
//   }

//   .StatusControlNext {
//     bottom: 12px;
//     right: 12px;
//   }

//   .Previews {
//     padding: 0 12px;
//   }
// }
