.transactions-pg {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.6rem;

    @media (max-width: 420px) {
      flex-direction: column;
      gap: 1.2rem;
    }
  }

  &__heading {
    font-size: 2.4rem;
    font-weight: 600;
  }

  &__filters {
    display: flex;
    align-items: center;
    gap: 2.4rem;
  }

  &__filter {
    display: flex;
    align-items: center;
    gap: 1.8rem;
  }
}
