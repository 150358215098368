.market {
  &__filter-block {
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
  }

  &__filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2.4rem;

    @media (max-width: 550px) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__filter-btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.2rem;
  }

  &__filter-btn {
    display: inline-block;
    background-color: var(--util-bg);
    border: 1px solid var(--util-border);
    height: 3.6rem;
    padding: 0 1.2rem;
    border-radius: 3px;
    font-family: inherit;
    cursor: pointer;
    color: var(--text-color);

    &--active,
    &:hover {
      background-color: $color-primary;
      color: $color-white;
      border: 1px solid $color-primary;
    }
  }

  &__search-form {
    height: 3.6rem;
    background-color: var(--util-bg);
    border: 1px solid var(--util-border);
    border-radius: 6px;

    display: flex;
    align-items: center;

    & button {
      display: block;
      width: 3.6rem;
      background-color: transparent;
      border: none;

      display: flex;
      align-items: center;
      justify-content: center;

      & ion-icon {
        width: 1.6rem;
        height: 1.6rem;
        color: var(--text-color);
        display: block;
      }
    }

    & input {
      color: var(--text-color);
      background-color: transparent;
      outline: none;
      margin-right: 6px;
      font-family: inherit;
      font-size: inherit;
      border: none;
    }
  }

  &__table-responsive {
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__table {
    width: 100%;
    border: 1px solid var(--market-border);
    border-collapse: collapse;

    & tr {
      background-color: var(--market-table-bg);
      border: 1px solid var(--market-border);
      height: 4.8rem;
    }

    & th {
      text-align: left;
      font-weight: 400;
      text-transform: uppercase;
      white-space: nowrap;
    }

    & td {
      font-weight: 600;
      font-size: 1.2rem;
    }

    & th,
    & td {
      padding: 0.6rem 1.2rem;
    }
  }

  &__table-heading {
    display: flex;
    align-items: center;
    gap: 2.4rem;
    cursor: pointer;
  }

  &__icon-stack {
    display: flex;
    flex-direction: column;

    & ion-icon {
      width: 1.2rem;
      height: 1.2rem;
      color: var(--sort-icon);
      visibility: visible;
    }

    &--active {
      color: var(--sort-icon-active) !important;
    }
  }

  &__currency {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    & img {
      height: 2.8rem;
    }
  }

  // @placeholder
  &__currency-plh-image {
    display: block;
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 3px;
  }

  &__currency-name {
    display: inline-block;
    white-space: nowrap;
    padding-right: 2rem;

    & span {
      text-transform: uppercase;
    }
  }

  &__trade-btn {
    display: inline-block;
    padding: 6px 2.4rem;
    background-color: $color-primary-dark;
    font-family: inherit;
    color: $color-white;
    border-radius: 8px;
    border: none;

    // @placeholder
    &--plh {
      background-color: transparent;
      color: transparent;
    }
  }

  &__change {
    display: flex;
    align-items: center;
    gap: 4px;

    &--danger {
      color: $color-danger;
    }

    &--success {
      color: $color-primary-dark;
    }

    & ion-icon {
      color: currentColor;
    }
  }

  &__chart {
    height: 4rem;
  }

  &__link {
    &:link,
    &:visited {
      font-size: 14px;
      color: $color-primary-dark;
      font-weight: 600;
    }
  }
}

.coins-block {
  padding: 2.4rem;
  border-radius: 0.8rem;
  background-color: var(--util-bg);
  border: 1px solid var(--util-border);

  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  &__heading {
    color: #397305;
    font-size: 1.8rem;
    font-weight: 700;
  }

  &__coins {
    width: 100%;
    border-collapse: collapse;

    & tr:not(:last-child) {
      border-bottom: 1.2rem solid transparent;
    }

    & td:nth-child(2) {
      padding: 0 1.2rem;
    }
  }
}
