.badge {
  display: inline-block;
  padding: 0.6rem 1.2rem;
  border-radius: 10rem;
  font-size: 1.25rem;

  &--btn {
    font-family: inherit;
    border: none;
    padding: 1.2rem 2.4rem;
  }

  &--str {
    padding: 0.6rem 2.4rem;
  }

  &--xs {
    padding: 4px 1.2rem;
    border-radius: 4px;
  }

  &--danger {
    background-color: rgba($color: $color-danger, $alpha: 0.2);
    color: $color-danger;
  }

  &--warning {
    background-color: rgba($color: $color-warning, $alpha: 0.2);
    color: $color-warning;
  }

  &--info {
    background-color: rgba($color: $color-info, $alpha: 0.2);
    color: $color-info;
  }

  &--success {
    background-color: rgba($color: $color-success, $alpha: 0.2);
    color: $color-success;
  }
}
