.auth-container {
  position: fixed;
  top: 0;
  left: 0;

  width: 100dvw;
  height: 100dvh;
  background-size: cover;

  padding: 0 2.4rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &--1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: initial;

    padding: 7.2rem 2.4rem;

    overflow-y: scroll;
  }
}

.auth {
  width: 45rem;
  max-width: 100%;
  background-color: var(--header-bg);
  padding: 3.6rem;
  border-radius: 1.2rem;

  &--1 {
    width: 70rem;
    padding: 5.6rem 10rem;
    border-radius: 3.2rem;

    @media (max-width: 500px) {
      padding: 5.6rem 3.6rem;
    }
  }

  &--md {
    width: 60rem;
    padding: 9.6rem 8rem;
  }

  @media (max-width: 450px) {
    padding: 2.4rem;
  }

  &__img-block {
    text-align: center;
    margin-bottom: 1.8rem;
  }

  &__img {
    display: inline-block;
    height: 6rem;
    cursor: pointer;

    &--1 {
      height: 18rem;
    }
  }

  &--1 &__img {
    height: 5rem;
  }

  &__heading {
    font-size: 2.2rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 6px;

    & span {
      text-transform: uppercase;
      font-style: italic;
    }
  }

  &__heading-sub-text {
    font-weight: 600;
    text-align: center;

    & a {
      &:link,
      &:visited {
        text-decoration: none;
        color: $color-orange;
      }
    }
  }

  &__form-main {
    margin-top: 2.4rem;

    display: flex;
    flex-direction: column;
    gap: 1.8rem;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    margin-top: 4.2rem;

    &--1 {
      align-items: center;
      margin-top: 0;

      & button {
        width: 35rem;
        max-width: 100%;
      }

      & a {
        &:link,
        &:visited {
          color: $color-accent;
          text-decoration: none;
        }
      }
    }
  }

  &__footer-link {
    text-align: right;

    & a {
      &:link,
      &:visited {
        color: $color-primary-dark;
        text-decoration: underline;
      }
    }
  }
}

// @media (max-width: 450px) {
//   .AuthContainer {
//     padding: 0 18px;
//   }
//   .Auth {
//     padding: 24px 18px;
//   }
//   .AuthImgBlock {
//     margin-bottom: 6px;
//   }
//   .AuthImg {
//     height: 40px;
//   }
//   .AuthHeading {
//     font-size: 18px;
//   }
// }
