.currencies {
  display: flex;
  flex-direction: column;

  padding-right: 7px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: var(--currencies-scrollbar-track);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--currencies-scrollbar-thumb);
  }
}

.currency {
  padding: 1rem;
  cursor: pointer;
  border-right: 3px solid transparent;

  display: flex;
  align-items: center;
  gap: 1.2rem;

  &--active {
    border-right: 3px solid $color-primary-darker;
    background-color: #1f4002 !important;
    color: #fff !important;
  }

  &:nth-child(even) {
    background-color: var(--currency-bg-1);

    &:hover {
      background-color: var(--currency-bg-1-hover);
    }
  }

  &:nth-child(odd) {
    background-color: var(--currency-bg-2);

    &:hover {
      background-color: var(--currency-bg-2-hover);
    }
  }

  &__img-block div,
  &__img-block img {
    display: block;
    width: 3.6rem;
    height: 3.6rem;
  }

  &__main {
    flex: 1;

    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  &__name {
    font-weight: 600;
  }

  &__price {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 1.2rem;
  }

  &__price-main {
    font-weight: 700;
    text-transform: uppercase;
  }
}

.currency1 {
  padding: 1.2rem 1.6rem;
  cursor: pointer;
  border-radius: 6px;
  background-color: var(--currency1-bg);
  overflow: hidden;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  gap: 1.2rem;

  &__img-block {
    flex-shrink: 0;

    & img {
      display: block;
      width: 3.6rem;
      height: 3.6rem;
    }
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
    color: #fff;
  }

  &__divider {
    flex-shrink: 0;

    width: 1px;
    height: 18px;
    background-color: #eaeaea;
  }

  &__main {
    flex: 1;

    display: flex;
    flex-direction: column;
  }

  &__abbr {
    font-size: 1.05rem;
    text-transform: uppercase;
  }

  &__name {
    font-weight: 600;
    word-wrap: break-word;
  }

  &__check {
    display: none;

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      color: #448c04;
    }
  }

  &--active &__check,
  &:hover &__check {
    display: block;
  }

  &--disabled &__check {
    display: none !important;
  }
}
