.rates {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  &__block {
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
  }

  &__heading {
    text-transform: uppercase;
    font-size: 1.8rem;
  }

  &__coin {
    display: flex;
    align-items: center;
    gap: 1.6rem;

    & img {
      display: block;
      width: 3.6rem;
    }
  }
}

.rate-table {
  width: 100%;
  border-collapse: collapse;

  & tr {
    cursor: pointer;
    border-top: 1px solid var(--accordion-border);

    &:hover {
      background-color: var(--accordion-hover);
    }
  }

  & thead tr {
    border: none;
  }

  & th {
    text-align: left;
    text-transform: uppercase;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    padding-right: 1.2rem;
    white-space: nowrap;

    &:first-child {
      padding-left: 2.4rem;
    }
  }

  & td {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    font-size: 1.4rem;
    font-weight: 500;
    white-space: nowrap;
    padding-right: 2.4rem;

    &:first-child {
      padding-left: 2.4rem;
      padding-right: 9.6rem;
    }

    &:last-child {
      padding-right: 1.2rem;
    }
  }
}
