.order-details {
  height: calc(100dvh - 7rem);
  overflow: hidden;

  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: calc((100dvw - 140rem) / 2 + 90rem);
    background-color: var(--header-bg);
    z-index: -1;
  }

  @media (max-width: 1400px) {
    &::after {
      display: none;
    }
  }

  &__main {
    max-width: 140rem;
    margin: auto;
    height: 100%;

    display: flex;
  }

  &__left {
    flex: 0 0 35rem;

    padding: 2.4rem;

    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: var(--currencies-scrollbar-track);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--currencies-scrollbar-thumb);
    }

    @media (max-width: 900px) {
      flex: 1;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }

  &__heading {
    font-size: 2rem;
    font-weight: 600;
  }

  &__hash {
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  &__type {
    background-color: $color-info;
    color: #fff;
    padding: 6px 1.2rem;
    font-family: inherit;
    border: none;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 0.6rem;
  }

  &__amt-block {
    display: flex;
    flex-direction: column;
  }

  &__amt-heading {
    font-weight: 400;
  }

  &__amt {
    font-size: 2.4rem;
    font-weight: 700;
  }

  &__right {
    flex: 1;

    background-color: var(--header-bg);
    padding: 2.4rem 0 1.2rem;

    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    &--centered {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: 900px) {
      position: fixed;
      top: 7rem;
      left: 0;
      width: 100dvw;
      height: calc(100dvh - 7rem);
      padding-top: 0;

      transform: translateX(100%);

      transition: all 0.3s;

      &--show {
        transform: translateX(0);
      }
    }
  }

  &__back {
    padding: 1.2rem;
    cursor: pointer;
    background-color: var(--body-bg);
    font-size: 1.6rem;

    display: none;
    align-items: center;
    gap: 1.2rem;

    @media (max-width: 900px) {
      display: flex;
    }
  }

  &__chat-block {
    flex: 1;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    padding: 0 2.4rem;

    @media (max-width: 450px) {
      padding: 0 1.2rem;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: var(--currencies-scrollbar-track);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--currencies-scrollbar-thumb);
    }
  }

  &__chat-footer {
    padding: 0 2.4rem;

    @media (max-width: 450px) {
      padding: 0 1.2rem;
    }
  }
}

.chat {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  width: 40rem;
  max-width: 80%;

  &--right {
    direction: rtl;
    margin-right: auto;
    padding-right: 0;
  }

  &__img {
    flex-shrink: 0;

    display: block;
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
  }

  &__msg {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  &__main {
    flex: 1;
    padding: 1.2rem;
    border-radius: 6px;
    text-align: left;
    max-width: fit-content;

    background-color: var(--chat-bg);
    color: var(--text-color);

    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &--right &__main {
    background-color: var(--chat-bg-1);
    border: 1px solid var(--chat-bg-1-border);
    color: var(--text-color);
  }

  &__imgs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 6px;

    height: 30rem;
    width: 30rem;
    direction: ltr;

    @media (max-width: 36rem) {
      width: 100%;
      height: 20rem;
    }

    & > div {
      overflow: hidden;
      cursor: pointer;

      position: relative;
    }

    &--1 {
      & > div:nth-child(1) {
        grid-row: 1 / -1;
        grid-column: 1 / -1;
      }
    }

    &--2 {
      & > div:nth-child(1) {
        grid-row: 1;
        grid-column: 1 / -1;
      }

      & > div:nth-child(2) {
        grid-row: 2;
        grid-column: 1 / -1;
      }
    }

    &--3 {
      & > div:nth-child(1) {
        grid-row: 1 / -1;
        grid-column: 1;
      }

      & > div:nth-child(2) {
        grid-row: 1;
        grid-column: 2;
      }

      & > div:nth-child(3) {
        grid-row: 2;
        grid-column: 2;
      }
    }

    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 2rem;
    cursor: pointer;
  }

  &__time {
    font-size: 1.1rem;
    direction: ltr;
  }

  &--right &__time {
    text-align: right;
  }
}

.chat-form {
  height: 48px;
  width: 100%;

  display: flex;
  gap: 1.2rem;

  padding: 6px;
  border: 1px solid var(--border);
  background-color: var(--header-bg);
  border-radius: 1.2rem;

  & input {
    flex: 1;
    font-family: inherit;
    color: var(--text-color);
    padding-left: 1.2rem;
    outline: none;
    min-width: 0;
    background-color: transparent;
    border: none;
  }

  &__send-img {
    cursor: pointer;

    & ion-icon {
      width: 3.2rem;
      height: 100%;
      color: $color-primary-darker;
    }
  }

  & button {
    display: inline-block;
    background-color: $color-primary-darker;
    color: #fff;
    border-radius: 8px;
    width: 10rem;
    border: none;
    font-family: inherit;
  }
}

.order-action {
  display: block;
  padding: 1.2rem 2.4rem;
  border: none;
  font-family: inherit;
  border-radius: 6px;
  cursor: pointer;

  &--success {
    background-color: rgba($color-success, 0.3);
    color: $color-success;
  }

  &--danger {
    background-color: rgba($color-danger, 0.3);
    color: $color-danger;
  }

  &--primary {
    background-color: rgba($color-info, 0.3);
    color: $color-info;
  }

  &--mobile {
    @media (min-width: 901px) {
      display: none;
    }
  }
}

// .DashboardMainLeft,
// .DashboardMainRight {
//   overflow: hidden;
// }

// .DashboardMainLeftContent {
//   max-width: 410px;
//   height: 100%;
//   margin-left: auto;
//   padding-top: 20px;
//   padding-left: 24px;
//   padding-right: 24px;
//   padding-bottom: 36px;
// }

// .DashboardMainLeftContent {
//   overflow-y: scroll;
// }

// .DashboardMainLeftContent::-webkit-scrollbar {
//   display: none;
// }
