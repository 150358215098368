.giftcard {
  cursor: pointer;
  box-shadow: 0 1.5rem 4rem var(--shadow-bg);
  border-radius: 0.8rem;
  overflow: hidden;

  & img {
    display: block;
    width: 100%;
  }

  &__pl-div {
    position: relative;

    & img {
      width: 100%;
      visibility: hidden;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background: var(--placeholder-bg);
      background: var(--placeholder-bg-2);

      background-size: 800px 104px;

      animation-duration: 1.25s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;
    }
  }

  &__name {
    height: 4.5rem;
    padding: 1.2rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;

    @media (max-width: 360px) {
      font-size: 1.2rem;
    }
  }
}

.giftcard-transactions {
  background-color: var(--header-bg);
  border: 1px solid var(--border);
  overflow: hidden;
  border-radius: 6px;

  &__header {
    padding: 2.4rem 2.4rem 0;
    background-color: var(--giftcard-transactions-header);
  }

  &__heading {
    font-weight: 600;
    margin-bottom: 2.4rem;
  }

  &__menu {
    list-style: none;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__menu-item {
    display: inline-block;
    padding: 0px 1.2rem 0.6rem;
    cursor: pointer;
    font-weight: 500;
    border-bottom: 3px solid transparent;
    transition: all 0.2s;

    &--active,
    &:hover {
      font-weight: 600;
      color: $color-primary-darker;
      border-bottom: 3px solid $color-primary-darker;
    }
  }

  &__empty {
    padding: 5.6rem 4.8rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;

    text-align: center;
    color: var(--text-color-primary);
  }

  &__empty-img {
    width: 10rem;
    display: inline;
  }

  &__empty-text {
    font-weight: 600;
  }

  &__empty-sub-text {
  }

  &__transaction {
    padding: 1.2rem 1.6rem;
    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 1.2rem;

    &:nth-child(even) {
      background-color: var(--currency-bg-1);

      &:hover {
        background-color: var(--currency-bg-1-hover);
      }
    }

    &:nth-child(odd) {
      background-color: var(--currency-bg-2);

      &:hover {
        background-color: var(--currency-bg-2-hover);
      }
    }
  }

  &__transaction-img {
    width: 4.2rem;
    height: 5.2rem;
    object-fit: cover;
  }

  &__transaction-main {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__transaction-info {
    font-weight: 600;
  }

  &__transaction-hash {
    font-size: 1.2rem;
  }

  &__transaction-amount {
    flex: 0 0 20%;
    margin-left: auto;
    font-size: 1.2rem;
    text-align: right;
  }
}
