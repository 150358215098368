.beneficiaries {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 1.2rem;
  }

  &__heading {
    font-size: 1.8rem;
    font-weight: 700;
  }

  &__beneficiaries {
    display: flex;
    align-content: center;
    gap: 12px;

    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.beneficiary {
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;

  padding: 6px 12px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;
  border: 1px solid transparent;

  &--active {
    border: 1px solid $color-primary;
    background-color: var(--giftcard-transactions-header);
  }

  &__name-logo {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 4rem;
    height: 4rem;
    border-radius: 50%;

    color: #fff;
    font-weight: 700;

    // background-color: #4687d5;
  }
}
