.loaderVBars-container {
  height: 4.2rem;
}

.loaderVBars {
  display: inline-block;
  position: relative;
  width: 8rem;
  height: 8rem;

  &--sm {
    transform: scale(0.5) translateY(-50%);
  }

  & div {
    display: inline-block;
    position: absolute;
    left: 0.8rem;
    width: 1.6rem;
    background: $color-primary;
    animation: loaderVBars 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }

  & div:nth-child(1) {
    left: 0.8rem;
    animation-delay: -0.24s;
  }

  & div:nth-child(2) {
    left: 3.2rem;
    animation-delay: -0.12s;
  }

  & div:nth-child(3) {
    left: 5.6rem;
    animation-delay: 0;
  }
}

.loaderRing {
  display: inline-block;
  width: 8rem;
  height: 8rem;

  &::after {
    content: " ";
    display: block;
    width: 6.4rem;
    height: 6.4rem;
    margin: 0.8rem;
    border-radius: 50%;
    border: 0.6rem solid $color-primary;
    border-color: $color-primary transparent $color-primary transparent;
    animation: loaderRing 1.2s linear infinite;
  }

  &--sm {
    transform: scale(0.4);
    margin-right: -2.8rem;
  }
}

.placeholder {
  background: var(--placeholder-bg);
  background: var(--placeholder-bg-2);

  background-size: 800px 104px;

  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
}

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  z-index: 99999999;

  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  transform: scale(0.5);

  & div {
    transform-origin: 40px 40px;
    animation: loaderSpinner 1.2s linear infinite;
  }

  & div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: $color-primary;
  }

  & div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }

  & div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }

  & div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }

  & div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }

  & div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }

  & div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }

  & div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }

  & div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }

  & div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }

  & div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }

  & div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }

  & div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
}

@keyframes l16 {
  0% {
    background-position: -150% 0, -150% 0;
  }
  66% {
    background-position: 250% 0, -150% 0;
  }
  100% {
    background-position: 250% 0, 250% 0;
  }
}

.loader {
  --c: no-repeat linear-gradient(#448c04 0 0);
  background: var(--c), var(--c), #dae8cd;
  background-size: 60% 100%;
  animation: l16 3s infinite;
}
