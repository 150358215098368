.accordion {
  border-radius: 1.2rem;
  overflow: hidden;
  position: relative;

  height: 5.6rem;

  &--open {
    height: 100%;
  }

  transition: all 0.3s;

  &:not(:last-child) {
    margin-bottom: 1.8rem;
  }

  &__header {
    padding: 0 2.4rem;
    cursor: pointer;
    background-color: var(--accordion-bg);
    position: relative;
    height: 5.6rem;

    z-index: 999;

    display: flex;
    align-items: center;
    gap: 1.6rem;
  }

  &--open &__header {
    box-shadow: 0 1.2rem 2.4rem var(--accordion-shadow);
  }

  &__toggle {
    cursor: pointer;

    margin-left: auto;

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;

      &:first-child {
        display: block;
      }

      &:last-child {
        display: none;
      }
    }
  }

  &--open &__toggle ion-icon {
    &:first-child {
      display: none;
    }

    &:last-child {
      display: block;
    }
  }

  &__header-img {
    display: block;
    width: 3.6rem;
    border-radius: 0.6rem;
    margin-left: -1.2rem;
  }

  &__body {
    padding: 1.8rem 2.4rem;
    background-color: var(--accordion-bg);

    position: relative;
    transform: translateY(-100%);

    transition: all 0.3s;

    & * {
      font-family: "Karla", sans-serif !important;
      color: var(--text-color) !important;
    }

    &--no-padding {
      padding: 0;
    }
  }

  &--open &__body {
    transform: translateY(0);
  }
}
