.user-welcome {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.2rem;

  &__img {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    object-fit: cover;
  }

  &__text {
    font-size: 1.6rem;
    margin-right: auto;

    display: flex;
    align-items: center;
    gap: 6px;

    & ion-icon {
      display: block;
      width: 1.6rem;
      height: 1.6rem;
    }

    & span {
      font-weight: 600;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 1.6rem;
  }

  &__btn {
    padding: 1.2rem 1.8rem;
    border: 1px solid $color-primary;
    background-color: transparent;
    border-radius: 6px;
    color: $color-primary;
    font-family: inherit;
    transition: all 0.2s;
    cursor: pointer;
    font-size: 1.2rem;

    &:hover {
      background-color: $color-primary;
      color: $color-white;
    }
  }
}

.profile-progress {
  background-color: var(--util-bg);
  border-radius: 8px;
  padding: 36px;
  cursor: pointer;

  @media (max-width: 360px) {
    padding: 1.8rem;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 2.4rem;

    font-size: 2.4rem;
    color: var(--text-color);
    font-weight: 500;

    & ion-icon {
      display: block;
    }
  }

  &__num {
    text-align: right;
    color: $color-primary;
  }

  &__progress {
    height: 4px;
    background-color: #d9d9d9;
    border-radius: 10rem;
    position: relative;
  }

  &__progress > * {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 60%;
    border-radius: 10rem;
    background-color: $color-primary;
  }
}

.services-section {
  padding: 2.4rem;
  background-color: var(--services-bg);
  border: 1px solid var(--util-border);
  border-radius: 8px;

  &__header {
    display: flex;
    align-items: center;
    gap: 2.4rem;
    justify-content: space-between;

    margin-bottom: 2.4rem;
  }

  &__heading {
    color: var(--text-color);
    font-weight: 600;
    font-size: 1.8rem;
  }

  &__link {
    color: $color-primary;
    text-decoration: none;
    font-weight: 400;
  }

  &__services {
    display: flex;
    justify-content: center;
    gap: 5.6rem;

    @media (max-width: 800px) {
      gap: 3.6rem;
    }

    @media (max-width: 620px) {
      gap: 2.4rem;
    }

    @media (max-width: 500px) {
      justify-content: flex-start;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.services-section-1 {
  &__header {
    display: flex;
    align-items: center;
    gap: 2.4rem;
    justify-content: space-between;

    margin-bottom: 2.4rem;
  }

  &__heading {
    color: var(--text-color);
    font-weight: 600;
    font-size: 2rem;
  }

  &__link {
    color: $color-primary;
    text-decoration: none;
    font-weight: 400;
  }

  &__services {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 2.4rem;

    @media (max-width: 1050px) {
      grid-template-columns: repeat(8, 1fr);
    }

    @media (max-width: 900px) {
      grid-template-columns: repeat(6, 1fr);
    }

    @media (max-width: 550px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 340px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.service {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;

  color: var(--text-color);
  cursor: pointer;
  white-space: nowrap;

  &__img {
    display: block;
    width: 3.6rem;
    height: 3.6rem;
  }
}

.status-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4.2rem;

  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 750px) {
    gap: 2.4rem;
  }

  @media (max-width: 500px) {
    justify-content: flex-start;
  }

  &__status {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    align-items: center;

    max-width: 20rem;
    cursor: pointer;

    @media (max-width: 750px) {
      gap: 0.6rem;
    }
  }

  &__img-box {
    border: 3px solid $color-primary;

    width: 9rem;
    height: 9rem;
    border-radius: 50%;
    padding: 4px;

    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 750px) {
      width: 7rem;
      height: 7rem;
    }

    & img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__text {
    white-space: nowrap;
    font-size: 1.2rem;
  }
}

.quick-actions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.2rem;

  @media (max-width: 750px) {
    display: flex;
    gap: 1.2rem;
    justify-content: flex-start;

    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.quick-action {
  padding: 2.4rem;
  border-radius: 1.2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 750px) {
    flex: 0 0 28rem;
  }

  &--1 {
    background-color: var(--service-bg-1);
  }

  &--2 {
    background-color: var(--service-bg-2);
  }

  &--3 {
    background-color: var(--service-bg-3);
  }

  &__icon-box {
    display: block;
    margin-bottom: 12px;
    background-color: $color-white;
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  &--1 &__icon-box ion-icon {
    color: #f9a21b;
  }

  &--2 &__icon-box ion-icon {
    color: #387305;
  }

  &--3 &__icon-box ion-icon {
    color: #25b2e7;
  }

  &__heading {
    font-weight: 700;
    margin-bottom: 2px;
  }

  &--1 &__heading {
    color: #f9a21b;
  }

  &--2 &__heading {
    color: #397305;
  }

  &--3 &__heading {
    color: #25b2e7;
  }

  &__text {
    font-size: 1.2rem;
    color: var(--text-color);
    font-weight: 500;

    margin-bottom: 1.6rem;
  }

  &__link {
    &:link,
    &:visited {
      display: inline-block;
      padding: 1.2rem 2.4rem;
      font-size: 1.2rem;
      color: $color-white;
      border-radius: 6px;
      text-decoration: none;
    }
  }

  &--1 &__link {
    background-color: #f9a21b !important;
  }

  &--2 &__link {
    background-color: #397305 !important;
  }

  &--3 &__link {
    background-color: #25b2e7 !important;
  }
}

.transactions-section {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2.4rem;
  }

  &__link {
    text-decoration: none;
    color: $color-primary;
    text-transform: uppercase;
    display: inline-block;
    cursor: pointer;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: currentColor;
    }
  }

  &__footer {
    text-align: center;
  }
}

.refer-section {
  display: flex;
  flex-direction: column;
  gap: 1.8rem;

  &__block {
    display: flex;
    align-items: center;
    gap: 2.4rem;

    @media (max-width: 600px) {
      flex-direction: column;
      gap: 1.8rem;
    }

    & > div:first-child {
      flex: 1;

      padding: 1.6rem;
      font-size: 1.6rem;
      border: 1px solid $color-primary;
      border-radius: 10rem;
      color: $color-primary;
      text-align: center;

      & a {
        &:link,
        &:visited {
          color: $color-primary;
          text-decoration: underline;
        }
      }
    }
  }
}

.info-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.4rem;

  @media (max-width: 950px) {
    grid-template-columns: 1fr;

    // display: flex;
    // overflow-x: scroll;

    // & > * {
    //   flex: 0 0 45rem;
    // }

    // &::-webkit-scrollbar {
    //   display: none;
    // }

    & > *:last-child {
      display: none !important;
    }
  }

  // @media (max-width: 500px) {
  //   display: grid;
  //   grid-template-columns: 1fr;
  // }
}

.info {
  position: relative;
  padding: 5.6rem;
  border-radius: 2.4rem;
  overflow: hidden;

  @media (max-width: 1100px) {
    padding: 3.2rem;
  }

  &--1 {
    color: #6d460a;
  }

  &--2 {
    color: #ffffff;
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    object-position: bottom right;
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  &__heading {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1.3;
  }

  &__text {
  }
}

.dashboard-banners {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.4rem;

  @media (max-width: 950px) {
    grid-template-columns: 1fr;

    & > *:last-child {
      display: none !important;
    }
  }
}

.dashboard-banner {
  height: 15rem;
  position: relative;
  overflow: hidden;
  border-radius: 1.6rem;

  &__controls {
    position: absolute;
    bottom: 8px;
    right: 8px;
    z-index: 9;

    display: flex;
    align-items: center;
    gap: 4px;

    & button {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      background-color: var(--body-bg);

      & ion-icon {
        display: block;
        color: var(--text-color);
      }
    }
  }

  &__main {
    height: 15rem;
    position: relative;
    overflow: hidden;
    border-radius: 1.6rem;
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
    transition: all 0.4s;
  }
}
