.icon {
  width: 80px;
  height: 80px;
  border: 4px solid gray;
  border-radius: 50%;
  margin: 20px auto;
  padding: 0;
  position: relative;
  box-sizing: content-box;
  transform: scale(1);
}

.icon.warning {
  border-color: #f8bb86;
}

.icon.warning .body {
  position: absolute;
  width: 5px;
  height: 36px;
  left: 50%;
  top: 16px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #f8bb86;
}

.icon.warning .dot {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: -3px;
  left: 50%;
  bottom: 16px;
  background-color: #f8bb86;
}
