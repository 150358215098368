.section {
  padding: 6.5rem 0;

  &__header {
    max-width: 140rem;
    margin: auto;

    margin-bottom: 4.8rem;
    background-color: var(--section-header-bg);
    padding: 4.8rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
  }

  &__heading {
    font-size: 2.8rem;
    font-weight: 500;
    margin-bottom: -0.8rem;
  }

  &__search-form {
    width: 70rem;
    max-width: 100%;
    background-color: var(--section-search-form-bg);

    height: 4.8rem;
    padding: 0 1.8rem;
    border-radius: 0.8rem;

    display: flex;
    align-items: center;
    gap: 1.2rem;

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      color: var(--text-color);
    }

    & input {
      flex: 1;
      min-width: 0;
      font-family: inherit;
      background-color: transparent;
      color: var(--text-color);
      outline: none;
      border: none;
      font-size: 1.6rem;
    }
  }

  &__main {
    max-width: 80rem;
    margin: auto;
    padding: 0 2.4rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    & p {
      text-align: justify;
    }

    & ul {
      list-style: disc;
      padding-left: 1.8rem;
    }

    & div {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
    }
  }
}
