.fireworks {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  z-index: 99999999999;
  pointer-events: none;
}

// Containers
.container {
  max-width: 130rem;
  margin: auto;
  padding: 0 2.4rem;
}

.dashboard-main {
  min-height: calc(100dvh - 7rem - 16rem);
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;

  display: flex;
  flex-direction: column;
  gap: 3.6rem;
}

.table-responsive {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.wm-90 {
  width: 90%;
  margin: auto;
}

.w-100 {
  width: 100% !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999999;
  cursor: pointer;
}

// Display
.light-block {
  display: var(--light-block) !important;
}

.light-inline-block {
  display: var(--light-inline-block) !important;
}

.dark-block {
  display: var(--dark-block) !important;
}

.dark-inline-block {
  display: var(--dark-inline-block) !important;
}

// Text Alignment
.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-lg {
  font-size: 2.4rem;
}

// Text Color
.link-primary {
  color: $color-primary !important;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.text-success {
  color: $color-success !important;
}

.text-warning {
  color: $color-warning !important;
}

.text-danger {
  color: $color-danger !important;
}

.text-info {
  color: $color-info !important;
}

.text-blue {
  color: #5a8dda !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-justify {
  text-align: justify !important;
}

// Spacings
.mb-0 {
  margin-bottom: 0 !important;
}

.mb-small {
  margin-bottom: 1.6rem;
}

.mb-medium {
  margin-bottom: 2.4rem;
}

.mb-large {
  margin-bottom: 4.8rem;
}

.mb-tiny {
  margin-bottom: 8px;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-small {
  margin-top: 1.6rem;
}

.mt-medium {
  margin-top: 2.4rem !important;
}

.mt-lg {
  margin-top: 3.6rem !important;
}

// Text
.bold {
  font-weight: 700 !important;
}

.color-transparent {
  color: transparent !important;
}

.font-hidden {
  font-family: "Narnoor", serif !important;
  font-weight: 400 !important;
}

// Others
.br-0 {
  border-radius: 0 !important;
}

.round {
  border-radius: 50%;
}

.bg-transparent {
  background-color: transparent !important;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info-block {
  padding: 2.4rem;
  text-align: center;
  background-color: var(--header-bg);
}

.hidden {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

.cursor-pointer {
  cursor: pointer;
}

.check-list {
  list-style: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;

  & li {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      color: $color-primary;
    }
  }
}

.text-underline {
  text-decoration: underline;
}

// Success and warning symbol
.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;
}

.check-icon {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  border: 4px solid #4caf50;

  &::before {
    top: 3px;
    left: -2px;
    width: 30px;
    transform-origin: 100% 50%;
    border-radius: 100px 0 0 100px;
  }

  &::after {
    top: 0;
    left: 30px;
    width: 60px;
    transform-origin: 0 50%;
    border-radius: 0 100px 100px 0;
    animation: rotate-circle 4.25s ease-in;
  }

  &::before,
  &::after {
    content: "";
    height: 100px;
    position: absolute;
    background: #ffffff;
    transform: rotate(-45deg);
  }

  .icon-line {
    height: 5px;
    background-color: #4caf50;
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 10;

    &.line-tip {
      top: 46px;
      left: 14px;
      width: 25px;
      transform: rotate(45deg);
      animation: icon-line-tip 0.75s;
    }

    &.line-long {
      top: 38px;
      right: 8px;
      width: 47px;
      transform: rotate(-45deg);
      animation: icon-line-long 0.75s;
    }
  }

  .icon-circle {
    top: -4px;
    left: -4px;
    z-index: 10;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: absolute;
    box-sizing: content-box;
    border: 4px solid rgba(76, 175, 80, 0.5);
  }

  .icon-fix {
    top: 8px;
    width: 5px;
    left: 26px;
    z-index: 1;
    height: 85px;
    position: absolute;
    transform: rotate(-45deg);
    background-color: #ffffff;
  }
}

.icon {
  width: 80px;
  height: 80px;
  border: 4px solid gray;
  border-radius: 50%;
  margin: 20px auto;
  padding: 0;
  position: relative;
  box-sizing: content-box;
  transform: scale(1);
}

.icon.warning {
  border-color: #f8bb86;
}

.icon.warning .body {
  position: absolute;
  width: 5px;
  height: 36px;
  left: 50%;
  top: 16px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #f8bb86;
}

.icon.warning .dot {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: -3px;
  left: 50%;
  bottom: 16px;
  background-color: #f8bb86;
}

.link {
  cursor: pointer;
  color: $color-info;
}

.c-icon {
  display: block;
  width: 2.4rem;
  height: 2.4rem;
}
