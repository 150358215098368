.testimonials {
  height: 35rem;

  position: relative;

  overflow: hidden;
}

.testimonial {
  width: 55%;
  height: 30rem;

  position: absolute;
  top: 0;
  left: 50%;

  opacity: 0.5;
  transform: translateX(-50%) scale(0.8);

  transition: all 0.4s;

  @media (max-width: 1200px) {
    width: 70%;
  }

  @media (max-width: 900px) {
    width: 90%;
  }

  @media (max-width: 650px) {
    width: 95%;
  }

  &::before {
    content: "";
    display: block;

    position: absolute;
    bottom: -1.8rem;
    left: -1.8rem;

    height: 90%;
    width: 90%;

    border-radius: 1.2rem;
    background-color: var(--testimonial-bg-2);
    z-index: -1;

    display: none;
  }

  &--active {
    opacity: 1;
    transform: translateX(-50%) scale(1);

    &::before {
      display: block;
    }
  }

  &__main {
    height: 100%;
    background-color: var(--testimonial-bg);
    padding: 4.8rem;
    border-radius: 1.2rem;

    @media (max-width: 650px) {
      padding: 2.4rem;
    }

    @media (max-width: 400px) {
      padding: 1.2rem;
    }
  }

  &__quote {
    font-size: 5.6rem;
    font-weight: 900;
    font-family: "Mulish", sans-serif;
    color: $color-primary;
  }

  &__msg {
    padding-left: 3.6rem;
    color: var(--text-color);
    margin-bottom: 1.8rem;
    margin-top: -1.8rem;

    @media (max-width: 700px) {
      padding-left: 0;
    }
  }

  &__author {
    text-align: right;
    color: $color-primary;
  }
}
