.form-card {
  display: block;
  width: 45rem;
  max-width: 100%;
  margin: 4.8rem auto 0;

  background-color: var(--header-bg);
  padding: 2.4rem;

  &__back {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    gap: 6px;

    & ion-icon {
      display: block;
    }
  }

  &__flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.8rem;
  }
}
