.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;

  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 99999999;

  &__logo-container {
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      background-color: var(--preloader-bg-1);
      z-index: -2;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &::before {
      content: "";
      display: block;
      width: 6rem;
      height: 6rem;
      border-radius: 50%;
      background-color: var(--preloader-bg-2);
      z-index: -1;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    & img {
      display: block;
      height: 7rem;

      animation-name: glow;
      animation-duration: 0.6s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }
  }
}
