.dashboard-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);

    display: flex;
    align-items: center;
    justify-content: center;

    width: 4.8rem;
    height: 4.8rem;
    background-color: #ffffff;
    border-radius: 50%;

    cursor: pointer;

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      color: #e53935;
    }
  }

  &__img {
    display: block;
    max-height: 60vh;
    max-width: calc(90vw - 2.4rem);
    border-radius: 2.4rem;

    &.desktop {
      @media (min-width: 500px) {
        display: block;
      }

      @media (max-width: 500px) {
        display: none;
      }
    }

    &.mobile {
      @media (min-width: 500px) {
        display: none;
      }

      @media (max-width: 500px) {
        display: block;
      }
    }
  }
}

.featured-section {
  padding: 4.8rem 0 0;

  &__main {
    width: 87.2rem;
    max-width: 100%;

    margin: auto;

    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 1.6rem;
  }

  &__header-line {
    flex: 1;
    height: 1px;
    background-color: var(--featured-heading-color);
  }

  &__heading {
    font-size: 2rem;
    font-weight: 600;
    font-family: "Sora", sans-serif;
    color: var(--featured-heading-color);
  }

  &__featured {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2.4rem;

    @media (max-width: 550px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__feature {
    height: 8rem;
    border-radius: 0.8rem;
    padding: 2.4rem;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--util-bg);

    transition: all 0.3s;

    & svg {
      display: block;
      width: 100%;
      max-height: 100%;
      color: var(--featured-color);

      transition: all 0.3s;
    }

    &:hover {
      background-color: #448c04;

      & svg {
        color: var(--featured-active-color);
      }
    }
  }
}

.features-section {
  padding: 4.8rem 0;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &__description {
    width: 25%;
    color: var(--text-color);
    font-size: 1.5rem;
  }

  &__features {
    // display: grid;
    // grid-template-columns: repeat(4, 1fr);
    // gap: 1.2rem;

    // @media (max-width: 1050px) {
    //   grid-template-columns: repeat(2, 1fr);
    // }

    // @media (max-width: 400px) {
    //   grid-template-columns: 1fr;
    // }

    display: flex;
    gap: 1.6rem;

    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    & > div {
      flex: 1;

      @media (max-width: 1100px) {
        flex: 0 0 30rem;
      }
    }
  }
}

.hv-card {
  width: 125.2rem;
  max-width: calc(100vw - 4.8rem);
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20rem;

  padding: 5.6rem;
  border-radius: 1.6rem;
  background-color: var(--vcard-home-bg);

  @media (max-width: 1250px) {
    gap: 10rem;
  }

  @media (max-width: 1250px) {
    gap: 4.8rem;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 10rem;
  }

  @media (max-width: 550px) {
    padding: 3.6rem;

    gap: 4.8rem;
  }

  &__main {
  }

  &__header {
    display: flex;
    flex-direction: column;

    margin-bottom: 2.4rem;
  }

  &__sub-heading {
    font-size: 1.8rem;
    font-family: "Sora", sans-serif;
    font-weight: 700;
    color: #a6a6a6;
  }

  &__heading {
    font-size: 2.8rem;
    font-weight: 700;
    font-family: "Sora", sans-serif;
    color: var(--vcard-heading-color);
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 1.8rem;

    color: var(--vcard-text-color);

    margin-bottom: 4rem;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;

    padding: 1.6rem 4rem;
    border-radius: 0.8rem;
    cursor: pointer;
    color: #ffffff;
    border: none;
    background-color: #448c04;
    font-family: inherit;

    & svg {
      display: block;
    }
  }

  &__img {
    display: block;
    width: 45.6rem;

    @media (max-width: 1250px) {
      width: 40rem;
    }

    @media (max-width: 1000px) {
      width: 35rem;
    }

    @media (max-width: 900px) {
      width: 50rem;
    }

    @media (max-width: 550px) {
      width: 100%;
    }
  }
}

.market-section {
  padding: 4.8rem 0;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &__coins-grid {
    display: flex;
    gap: 1.6rem;

    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    & > div {
      flex: 1;
    }
  }
}

.cta-section {
  padding: 4.8rem 0;
}

.cta {
  background-color: var(--why-choose-bg);
  border-radius: 2.4rem;

  &__main {
    padding: 0 4.8rem;

    @media (max-width: 500px) {
      padding: 0 1.2rem;
    }
  }

  &__main-img {
    height: 20rem;
    transform: translateY(-25%);
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4.8rem;

    padding: 0 2.4rem;
    color: $color-white;

    @media (max-width: 1100px) {
      gap: 4.8rem;
    }

    @media (max-width: 1050px) {
      grid-template-columns: 1fr;
    }

    @media (max-width: 500px) {
      padding: 0 1.2rem;
    }
  }

  &__heading {
    font-size: 1.8rem;
    margin-bottom: 1.2rem;
  }

  &__sub-heading {
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
  }

  &__text {
    font-size: 1.4rem;
    margin-bottom: 3.6rem;
  }

  &__link {
    &:link,
    &:visited {
      display: inline-block;
      padding: 1.2rem 3.6rem;
      border-radius: 100px;
      color: #141414;
      background-color: $color-white;
      font-weight: 600;
      text-decoration: none;
    }
  }

  &__link-2 {
    &:link,
    &:visited {
      text-decoration: underline;
      color: $color-white;
    }
  }

  &__footer-1 {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    color: $color-white;
  }

  &__footer {
    text-align: right;
    margin-top: -5rem;
  }

  &__footer-img {
    height: 20rem;
    display: inline;
  }

  &__info {
    display: flex;
    gap: 1.2rem;
  }

  &__info-icon-block {
    & img {
      height: 3.6rem;
    }
  }
}

.influencers-section {
  &__container {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
    background-color: var(--header-bg);
  }

  &__img {
    display: block;
    width: 100%;
    object-fit: cover;
  }
}

.influencer-join-section {
  padding: 9.6rem 0;
}

.ijcard {
  position: relative;
  padding: 9.6rem;
  color: #fff;
  border-radius: 3rem;
  overflow: hidden;

  @media (max-width: 1200px) {
    padding: 9.6rem 4.8rem;
  }

  @media (max-width: 600px) {
    padding: 4.8rem;
  }

  @media (max-width: 400px) {
    padding: 2.4rem;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
    display: block;
    z-index: -1;

    @media (max-width: 750px) {
      object-fit: cover;
    }
  }

  &__top-heading {
    font-family: Sora;
    font-size: 2rem;
    margin-bottom: 2.4rem;
  }

  &__main {
    display: flex;
    gap: 5.6rem;

    @media (max-width: 1200px) {
      flex-direction: column;
      gap: 2.4rem;
    }

    & > * {
      flex: 1;
    }
  }

  &__heading {
    font-family: Sora;
    font-size: 3.6rem;
    font-weight: 600;
    letter-spacing: -0.054rem;
    color: #f2f2f2;

    @media (max-width: 500px) {
      font-size: 2.8rem;
    }
  }

  &__text {
    color: #f2f2f2;
    font-size: 2.4rem;
    line-height: 1.2;

    margin-bottom: 3.6rem;

    @media (max-width: 500px) {
      font-size: 1.8rem;
    }
  }

  &__button {
    border-radius: 0.8rem;
    background-color: #fff;
    padding: 1.6rem 4.5rem;
    border-radius: 0.8rem;
    font-family: inherit;
    font-weight: 500;
    cursor: pointer;
    border: none;
    color: #0f0f1f;
  }
}

.socials-section {
  padding: 4.8rem 0;

  &__socials {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2.4rem;

    @media (max-width: 900px) {
      gap: 1.2rem;
    }

    @media (max-width: 750px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 4.8rem;
    }

    @media (max-width: 500px) {
      grid-template-columns: 1fr;
    }
  }

  &__social {
    &:link,
    &:visited {
      cursor: pointer;
      border-radius: 6px;
      transition: all 0.3s;
      padding: 2.4rem;
      text-decoration: none;
      display: block;
    }

    &:hover {
      box-shadow: 0 1.5rem 4rem var(--shadow-bg);
    }
  }

  &__social-img {
    display: inline-block;
    width: 3.2rem;
    margin-bottom: 2.4rem;
  }

  &__social-heading {
    font-size: 1.8rem;
    margin-bottom: 1.2rem;
    font-weight: 700;
    color: var(--text-color);
  }

  &__social-description {
    color: var(--text-color);
    font-weight: 300;
  }
}

.testimonials-section {
  padding: 4.8rem 0;

  &__header {
    text-align: center;
    font-size: 3.2rem;
    font-weight: 700;

    margin-bottom: 3.6rem;
  }
}

// .blog-section {
//   padding: 4.8rem 0;
// }
