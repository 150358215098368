.table-responsive {
  overflow-x: scroll;

  &--no-hide {
    display: block !important;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 950px) {
    display: none;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;

  & thead tr {
    background-color: transparent;
  }

  & tr {
    // background-color: var(--header-bg);
    background-color: var(--util-bg);
    cursor: pointer;
    white-space: nowrap;

    &:not(:last-child) {
      // border-bottom: 1px solid var(--border-2);
      border-bottom: 6px solid var(--body-bg);
    }
  }

  & tbody tr:hover {
    // background-color: var(--table-hover);
  }

  & th {
    text-align: left;
    text-transform: uppercase;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    padding-right: 2.4rem;
  }

  & td {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    color: var(--text-color);
    white-space: nowrap;
    padding-right: 2.4rem;

    &:first-child {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
    }

    &:last-child {
      padding-right: 1.2rem;
    }
  }
}
