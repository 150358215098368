.form-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;

  text-align: center;
  padding: 0 1.2rem;
  height: 4.8rem;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  font-family: inherit;
  background-color: var(--form-button-inactive-bg);
  color: var(--form-button-inactive-text-color);

  &--md {
    height: 5.6rem;
  }

  &--danger {
    background-color: $color-danger;
    color: #fff;

    &:disabled {
      opacity: 0.8;
    }
  }

  & ion-icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
  }

  &--active {
    background-color: $color-primary-dark;
    color: $color-white;
  }
}

a.form-button {
  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;
}

.button {
  display: inline-block;
  padding: 1.2rem 2.4rem;
  cursor: pointer;
  background-color: $color-primary-darker;
  color: $color-white;
  border: none;
  font-family: inherit;
  border-radius: 0.6rem;

  &--danger {
    background-color: $color-danger;
  }
}

.tab-buttons {
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
  background-color: rgba($color: $color-primary, $alpha: 0.1);
  height: 6.8rem;
  border-radius: 1.2rem;
  padding: 6px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  &--sm {
    height: 4.8rem;
    border-radius: 0.8rem;
  }

  &--md {
    height: 6.2rem;
    border-radius: 0.8rem;
  }

  &--auto {
    max-width: initial;
    margin: 0;
    width: initial;
  }

  &--reg {
    width: 30rem;
    max-width: 100%;
    margin: auto;
    height: 6.2rem;
    border-radius: 0.8rem;
    background-color: var(--form-bg);
    border: 1px solid var(--border);
  }

  @media (max-width: 700px) {
    &--wallet {
      display: none;
    }
  }

  @media (max-width: 900px) {
    &--orders {
      display: none;
    }
  }

  @media (max-width: 500px) {
    &--bills {
      display: none;
    }
  }

  & button {
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    color: $color-primary;
    border-radius: 1.2rem;
    border: none;
    cursor: pointer;
    background-color: transparent;
    cursor: pointer;
    font-family: inherit;
    font-size: 1.8rem;

    transition: all 0.2s;

    &.active {
      background-color: $color-primary;
      color: #fff;
    }
  }

  &--sm button {
    font-size: 1.4rem;
  }

  &--md button {
    border-radius: 0.8rem;
  }

  &--auto button {
    flex: initial;
    padding: 0 2.4rem;
  }

  &--reg button {
    font-size: 1.45rem;
    border-radius: 0.8rem;
    color: var(--text-color);

    &.active {
      background-color: $color-primary-lighter;
    }
  }

  @media (max-width: 1200px) {
    &--wallet button {
      padding: 0 1.8rem;
    }
  }

  &--sm button {
    border-radius: 0.8rem;
  }
}

.tab-buttons-1 {
  display: flex;
  align-items: center;
  gap: 2.4rem;

  & button {
    display: block;
    border: none;
    padding-bottom: 6px;
    background-color: transparent;

    cursor: pointer;

    font-size: 1.6rem;
    font-family: inherit;
    color: var(--featured-heading-color);

    position: relative;

    transition: all 0.3s;

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;

      background-color: #397305;

      opacity: 0;

      transition: all 0.3s;
    }

    &:hover {
      color: #397305;
    }

    &.active {
      color: #397305;

      &::after {
        opacity: 1;
      }
    }
  }
}
