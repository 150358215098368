.dispute {
  display: flex;
  flex-direction: column;

  height: calc(100dvh - 7rem);

  overflow-y: hidden;

  &__main {
    flex: 1;

    height: calc(100dvh - 7rem - 8rem);
    padding: 2.4rem;

    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    @media (max-width: 450px) {
      padding: 1.8rem 0;
    }
  }
}

.dispute-header {
  height: 8rem;
  background-color: var(--header-bg);
  padding: 1.6rem;

  display: flex;
  align-items: center;
  gap: 1.2rem;

  &__main {
    display: flex;
    flex-direction: column;
    gap: 6px;

    & p:first-child {
      font-weight: 600;
    }

    // & p:last-child {
    // }
  }

  &__img {
    display: block;
    height: 5rem;
    border-radius: 3px;
  }
}
