.register {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;

  display: flex;

  &__left {
    background-color: $color-primary-dark;
    overflow: hidden;
    width: 45rem;
    padding: 4.8rem 3.6rem;

    position: relative;
  }

  &__logo-box {
    text-align: center;
    margin-bottom: 5.6rem;

    & img {
      height: 4.5rem;
      display: inline-block;
    }
  }

  &__steps {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__copyright {
    position: absolute;
    bottom: 4.8rem;
    color: $color-white;
    padding-right: 4.8rem;
    text-align: center;

    & span {
      color: $color-red;
    }
  }

  &__main {
    flex: 1;

    overflow-x: hidden;
    overflow-y: scroll;

    background-size: cover;

    display: flex;
    align-items: center;
    padding: 0 2.4rem;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__main-content {
    width: 100%;
    max-width: 40rem;
    margin: auto;
  }

  &__heading {
    text-align: center;
    font-weight: 500;
    font-size: 2.4rem;
    margin-bottom: 0.6rem;

    & span {
      font-style: italic;
    }
  }

  &__heading-sub-text {
    text-align: center;
  }

  &__img-box {
    text-align: center;
    padding-top: 4.8rem;

    & img {
      display: inline-block;
      height: 4.8rem;
    }
  }

  &__form {
    padding-bottom: 4.8rem;
  }

  &__resend-code {
    text-align: center;

    & span {
      color: $color-primary;
      display: inline-block;
      border-bottom: 1px solid rgba($color: $color-primary, $alpha: 0.6);
      cursor: pointer;
    }
  }

  &__dob-input-group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;

    & > * {
      flex: 1;
    }
  }

  &__pin-input-group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
  }

  &__pin-input-group-2 {
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }

  &__pin-input {
    display: block;
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 0.6rem;
    border: 1px solid var(--border);
    background-color: var(--form-bg);
    text-align: center;
    outline: none;
    font-family: inherit;
    color: var(--text-color);
  }

  &__form-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.8rem;
  }

  @media (max-width: 1050px) {
    &__left {
      display: none;
    }
  }
}

.register-step {
  display: flex;
  gap: 1.2rem;
  width: 30rem;

  &__left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__btn {
    display: block;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    background-color: transparent;

    border: 3px solid rgba(255, 255, 255, 0.3);

    display: flex;
    align-items: center;
    justify-content: center;

    & ion-icon {
      display: none;

      width: 1.4rem;
      height: 1.4rem;
      color: $color-primary-dark;
    }

    &::after {
      content: "";
      display: block;
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;

      background-color: rgba(255, 255, 255, 0.3);
    }
  }

  &--active &__btn {
    border: 3px solid $color-white;

    &::after {
      background-color: $color-white;
    }
  }

  &--completed &__btn {
    background-color: $color-white;

    & ion-icon {
      display: block;
    }

    &::after {
      display: none;
    }
  }

  &__line {
    flex: 1;
    width: 3px;
    background-color: rgba(255, 255, 255, 0.3);
  }

  &--completed &__line {
    background-color: $color-white;
  }

  &:last-child &__line {
    display: none;
  }

  &__main {
    padding-bottom: 2.4rem;
  }

  &:last-child &__main {
    padding-bottom: 0;
  }

  &__heading {
    color: rgba(255, 255, 255, 0.3);
    margin-bottom: 2px;
  }

  &--completed &__heading,
  &--active &__heading {
    color: $color-white;
  }

  &__text {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.3);
  }

  &--completed &__text,
  &--active &__text {
    color: $color-white;
  }
}
