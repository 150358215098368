.wallet {
  position: fixed;
  top: 7rem;
  left: 0;
  width: 100dvw;
  height: 100dvh;

  display: flex;

  &__sidebar {
    flex: 0 0 35rem;
    background-color: var(--util-bg);
    height: calc(100dvh - 7rem);

    display: flex;
    flex-direction: column;

    transition: all 0.3s;

    @media (max-width: 1100px) {
      position: fixed;
      top: 7rem;
      left: 0;
      width: 100dvw;
      z-index: 999;

      &--hidden {
        transform: translateX(-100%);
      }
    }
  }

  &__sidebar-header {
    flex-shrink: 0;

    padding: 1.2rem;

    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }

  &__sidebar-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4rem;
    padding: 0 1.6rem;
    background-color: rgba($color: $color-primary, $alpha: 0.1);
    border-radius: 0.8rem;
    color: $color-primary;

    & > p:first-child {
      font-weight: 700;
    }
  }

  &__sidebar-main {
    flex: 1;

    overflow-y: scroll;
  }

  &__main {
    flex: 1;
    height: calc(100dvh - 7rem);
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 1100px) {
      flex: 0 0 100dvw;
    }
  }

  &__main-content {
    min-height: calc(100dvh - 7rem - 16rem);
    padding: 1.2rem;

    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  &__show-all {
    display: flex;
    align-items: center;
    gap: 1.8rem;

    background-color: var(--header-bg);
    border: 1px solid var(--border);
    padding: 1.8rem;
    border-radius: 0.8rem;
    cursor: pointer;

    @media (min-width: 1100px) {
      display: none;
    }
  }

  &__select-network {
    background-color: var(--util-bg);
    padding: 4.8rem;
    border-radius: 0.8rem;
  }

  &__select-network-heading {
    font-size: 1.8rem;
  }

  &__select-network-main {
    max-width: 40rem;
    margin: auto;

    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    text-align: center;
  }

  // &__deposit-chart {
  //   &--filled {
  //     display: grid;
  //     grid-template-columns: repeat(2, 1fr);
  //     gap: 2.4rem;

  //     & > * {
  //       width: 100%;
  //       max-width: initial !important;
  //     }
  //   }
  // }
}

.wallet-card {
  background-color: var(--util-bg);
  border: 1px solid var(--util-border);
  padding: 4.8rem;
  border-radius: 0.8rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 550px) {
    flex-direction: column;
    justify-content: initial;
    gap: 24px;
  }

  @media (max-width: 360px) {
    padding: 4.8rem 2.4rem;
  }

  &__main {
    display: flex;
    flex-direction: column;

    color: $color-primary-darker;
  }

  &__header {
    font-weight: 600;

    display: flex;
    align-items: center;
    gap: 2.4rem;

    @media (max-width: 550px) {
      justify-content: center;
    }

    & span {
      cursor: pointer;
    }

    & ion-icon {
      display: block;
      width: 2rem;
      height: 2rem;
    }
  }

  &__amount {
    display: flex;
    flex-direction: column;

    @media (max-width: 550px) {
      text-align: center;
    }
  }

  &__amount-currency {
    font-size: 4.8rem;
    font-weight: 600;
  }

  &__amount-usd {
    font-weight: 600;
    font-size: 2.4rem;
    color: var(--usd-color);
  }

  &__rate-block {
    margin-top: 1.2rem;

    display: flex;
    align-items: center;
    gap: 1.2rem;

    @media (max-width: 550px) {
      justify-content: center;
    }
  }

  &__rate {
    padding: 0.8rem 1.6rem;
    border-radius: 10rem;

    background-color: rgba($color: $color-warning, $alpha: 0.2);
    color: $color-warning;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }

  &__action {
    padding: 2.4rem;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;

    &:hover,
    &--active {
      background-color: var(--wallet-card-action-bg);
    }

    & img {
      width: 2.4rem;
      height: 2.4rem;
      display: block;
    }
  }
}

.wallet-operations {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 550px) {
    flex-direction: column;
    gap: 1.8rem;
  }

  &__quick-links {
    list-style: none;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2.4rem;
  }

  &__quick-link {
    &:link,
    &:visited {
      text-decoration: none;
      color: var(--text-color);

      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &__quick-link-icon-box {
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    background-color: rgba($color: $color-primary, $alpha: 0.1);

    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      color: $color-primary;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    @media (max-width: 550px) {
      order: -1;
    }
  }

  &__btn {
    display: inline-block;
    padding: 1.8rem 4.2rem;
    color: $color-white;
    border-radius: 0.6rem;
    font-family: inherit;
    cursor: pointer;
    border: none;
    text-decoration: none;
    line-height: 1;
    font-size: 1.8rem;

    &--deposit {
      background-color: #0f5ffc;
    }

    &--withdraw {
      background-color: #e53935;
    }

    &--withdraw-1 {
      background-color: #f9a21b;
    }

    &--transfer {
      background-color: #448c04;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.wallet-bulk {
  position: relative;

  color: #ffffff;
  border-radius: 2.4rem;
  overflow: hidden;

  &--sidebar {
    @media (max-width: 1100px) {
      display: none;
    }
  }

  &--wallet {
    display: none;

    @media (max-width: 1100px) {
      display: block;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: fill;
    background-position: right;
    z-index: 1;
    // object-position: right;
  }

  &__bg--small {
    display: none;

    @media (max-width: 650px) {
      display: block;
    }
  }

  &__bg--big {
    display: block;

    @media (max-width: 650px) {
      display: none;
    }
  }

  &--sm &__bg--small {
    display: block;
  }

  &--sm &__bg--big {
    display: none;
  }

  &--wallet &__bg--small {
    display: none;

    @media (max-width: 650px) {
      display: block;
    }
  }

  &--wallet &__bg--big {
    display: block;

    @media (max-width: 650px) {
      display: none;
    }
  }

  &__main {
    padding: 3.2rem;
    z-index: 11;
    position: relative;

    max-width: 80rem;

    @media (max-width: 450px) {
      padding: 3.2rem;
    }
  }

  &--sm &__main {
    padding: 1.8rem;
  }

  &__heading {
    font-size: 2.8rem;
    line-height: 1.1;
    font-weight: 700;
    text-transform: uppercase;

    margin-bottom: 1.6rem;

    // @media (max-width: 450px) {
    //   font-size: 2.6rem;
    // }
  }

  &--sm &__heading {
    font-size: 1.8rem;
    line-height: 1.5;

    margin-bottom: 0.8rem;
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    flex-wrap: wrap;

    font-size: 2rem;

    @media (max-width: 450px) {
      font-size: 1.6rem;
    }
  }

  &--sm &__info {
    font-size: 1.4rem;
  }

  &__link {
    &:link,
    &:visited {
      text-decoration: underline;
      font-weight: 700;
      color: inherit;
    }
  }
}

.wallet-info-block {
  display: flex;
  gap: 2.4rem;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  &__main {
    flex: 1;
  }

  &__right {
    flex: 0 0 35rem;

    @media (max-width: 900px) {
      flex: initial;
    }
  }
}

.wallet-info {
  background-color: var(--util-bg);
  border: 1px solid var(--util-border);
  border-radius: 6px;

  &__heading {
    padding: 1.8rem;
    border-bottom: 1px solid var(--border);
  }

  &__body {
    padding: 1.8rem;
  }

  &__coin {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    font-size: 2rem;
    font-weight: 700;

    margin-bottom: 1.6rem;

    & img {
      display: block;
      width: 3.6rem;
      height: 3.6rem;
    }
  }

  &__rate {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 4rem 2.4rem;
    background-color: var(--body-bg);
    font-size: 2rem;
    color: $color-primary;
    font-weight: 700;

    margin-bottom: 1.6rem;
  }

  &__change {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }

  &__detail {
    font-weight: 600;
  }

  // &__detail-title {
  // }

  &__detail-value {
    font-size: 1.8rem;

    &--1 {
      color: #0d308c;
    }

    &--2 {
      color: #397305;
    }

    &--3 {
      color: #f91b1b;
    }
  }

  &__divider {
    height: 1px;
    background-color: var(--border);
  }
}

.wallet-chart {
  background-color: var(--util-bg);
  border: 1px solid var(--util-border);
  padding: 2.4rem;
  border-radius: 6px;

  width: 60rem;
  max-width: 100%;
  margin: auto;

  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  &--1 {
    width: initial;
    margin: initial;
    min-width: 0;
  }

  @media (max-width: 550px) {
    width: 100%;
    margin: initial;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.6rem;

    & p {
      font-size: 1.25rem;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.6rem;

    font-size: 1.25rem;

    @media (max-width: 500px) {
      & p {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.wallet-deposit {
  background-color: var(--util-bg);
  border: 1px solid var(--util-border);
  padding: 2.4rem;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  &__heading {
    font-size: 1.8rem;
  }

  &__text {
  }

  &__recieve-steps {
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
  }

  &__qr-container {
    width: 40rem;
    max-width: 100%;
    text-align: center;
  }

  &__qr-img {
    display: inline-block;
    width: 25rem !important;
    height: 25rem !important;

    &--small {
      width: 15rem !important;
      height: 15rem !important;
    }
  }

  &__qr-link {
    background-color: rgba($color: $color-primary, $alpha: 0.1);
    padding: 4px;
    border-radius: 6px;

    width: 40rem;
    max-width: 100%;

    display: flex;
    align-items: stretch;
    flex-direction: column;

    & div {
      color: var(--text-color);
      padding: 0.8rem 1.2rem;
      word-wrap: break-word;
      text-align: center;
    }

    &--small div {
      padding: 6px;
    }

    & button {
      display: inline-block;
      padding: 0.8rem;
      border-radius: 6px;
      font-family: inherit;
      border: none;
      cursor: pointer;

      background-color: rgba($color: $color-primary, $alpha: 0.1);
      color: $color-primary;
    }
  }
}

.wallet-transactions {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2.4rem;

    margin-bottom: 2.4rem;
  }

  &__header-main {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  &__heading {
    font-size: 2rem;
    font-weight: 600;
  }

  &__filter {
    @media (min-width: 700px) {
      display: none;
    }
  }
}

.wallet-transaction-block {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  &:not(:last-child) {
    margin-bottom: 2.4rem;
  }

  &__header {
    font-size: 1.6rem;
    text-transform: uppercase;

    display: flex;
    align-items: center;
    gap: 1.4rem;

    & div {
      flex: 1;
      height: 1px;
      background-color: var(--border);
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
  }
}

.minimum-deposit {
  padding: 0.6rem 1.2rem;
  border-radius: 6px;
  background-color: var(--service-bg-1);

  display: inline-flex;
  align-items: center;
  gap: 0.8rem;

  & ion-icon {
    display: block;
    width: 1.8rem;
    height: 1.8rem;
    color: #f9a21b;
  }
}
