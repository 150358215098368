.feature {
  padding: 3.2rem;
  border-radius: 0.6rem;

  &--1 {
    background-color: var(--feature-bg-1);
  }

  &--2 {
    background-color: var(--feature-bg-2);
  }

  &--3 {
    background-color: var(--feature-bg-3);
  }

  &--4 {
    background-color: var(--feature-bg-4);
  }

  &__img {
    display: inline-block;
    height: 15rem;
    margin-bottom: 2.4rem;
  }

  &__heading {
    color: var(--feature-heading-color);
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 1.2rem;
  }

  &__text {
    font-size: 1.35rem;
    color: var(--feature-description-color);
    font-weight: 500;

    margin-bottom: 1.6rem;
  }

  &__link {
    &:link,
    &:visited {
      color: var(--feature-link-color);
      font-weight: 600;
      text-decoration: none;

      & span {
        display: inline-block;
        border-bottom: 1px solid currentColor;
      }
    }
  }
}
