.heading-secondary {
  font-size: 2.8rem;
  font-weight: 700;
}

.heading-tertiary {
  font-size: 2.2rem;
  font-weight: 700;
}

.influencer-heading {
  font-family: "Lily Script One", cursive;
  font-size: 3.6rem;
  font-weight: 400;
  margin-bottom: -2.4rem;

  @media (max-width: 500px) {
    font-size: 3rem;
  }
}

.font-large {
  font-size: 1.8rem;
  font-weight: 500;

  @media (max-width: 460px) {
    text-align: center;
  }
}

// Move to component eventually
.qr {
  display: flex;
  flex-direction: column;
  gap: 1.8rem;

  &__text {
    font-size: 1.2rem;
  }

  &__table-container {
    display: flex;
    justify-content: center;
  }

  &__table {
    border-spacing: 1.2rem 0.8rem;
  }

  &__cell {
    vertical-align: top;
  }

  &__block {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 15rem;
    height: 15rem;
    background-color: var(--header-bg);

    & img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &__key {
    font-size: 2.2rem;
    font-weight: 600;

    display: flex;
    align-items: center;
    gap: 1.2rem;
  }
}
