$uiMode: light;

$light-block: if($uiMode == light, block, none);
$light-inline-block: if($uiMode == light, inline-block, none);

$dark-block: if($uiMode == dark, block, none);
$dark-inline-block: if($uiMode == dark, inline-block, none);

$body-bg: if($uiMode == light, #fafafa, #0a1401);

$util-bg: if($uiMode == light, #fff, #0c1901);

$text-color: if($uiMode == light, #2b2b2b, #e6e6e6);
$text-color-dark: if($uiMode == light, #141414, #e6e6e6);
$text-color-primary: if($uiMode == light, rgba(56, 115, 5, 0.6), #fff);

$header-bg: if($uiMode == light, #fff, #0e1d01);

$hero-bg: if($uiMode == light, #ecf2e6, #0c1901);
$hero-heading-color: if($uiMode == light, #0f0f1f, #e6e6e6);
$hero-description-color: if($uiMode == light, #397305, #e6e6e6);

$stats-bg: if($uiMode == light, #fff, #141414);
$stats-icon-bg: if($uiMode == light, #fff, #e6e6e6);

$border: if($uiMode == light, #d7d7d7, #244903);
$border-2: if($uiMode == light, #f7f7f7, #1a1a1a);
$border-3: if($uiMode == light, #ccc, #333);
$util-border: if($uiMode == light, #eaeaea, #132702);

$feature-bg-1: if($uiMode == light, #f2f2ec, #2d2d1f);
$feature-bg-2: if($uiMode == light, #fff2e4, #4d2800);
$feature-bg-3: if($uiMode == light, #e3f3fe, #032d4a);
$feature-bg-4: if($uiMode == light, #f2ebfe, #1d0449);

$feature-heading-color: if($uiMode == light, #0f0f1f, #e6e6e6);
$feature-description-color: if($uiMode == light, #22223a, #b3b3b3);
$feature-link-color: if($uiMode == light, #141414, #e6e6e6);

$market-bg: if($uiMode == light, #ffffff, #141414);
$market-table-bg: if($uiMode == light, #ffffff, transparent);
$market-border: if($uiMode == light, #eaeaea, #333333);

$sort-icon: if($uiMode == light, #bdbdbd, #333333);
$sort-icon-active: if($uiMode == light, #000, #e6e6e6);

$why-choose-bg: if($uiMode == light, #1a3502, #1a3502);

$testimonial-bg: if($uiMode == light, #fff, #0e1d01);
$testimonial-bg-2: if($uiMode == light, #ecf2e6, #112201);

$footer-bg: if($uiMode == light, #ecf2e6, #0e1d01);

$accordion-bg: if($uiMode == light, #fff, #0c1901);
$accordion-shadow: if($uiMode == light, #f7f7f7, rgba(#000, 0.2));
$accordion-border: if($uiMode == light, #f7f7f7, transparent);
$accordion-hover: if($uiMode == light, #f7f7f7, transparent);

$section-header-bg: if($uiMode == light, #ecf2e6, #0e1d01);
$section-search-form-bg: if($uiMode == light, #fff, #0c1901);

$form-bg: if($uiMode == light, transparent, #0c1901);

$preloader-bg-1: if($uiMode == light, #e8f8d8, #334d1a);
$preloader-bg-2: if($uiMode == light, #d5eebb, #3b581d);

$placeholder-bg: if($uiMode == light, darkgray, #141414);
$placeholder-bg-2: if(
  $uiMode == light,
  linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%),
  linear-gradient(to right, #183102 10%, #132702 18%, #183102 33%)
);

$form-button-inactive-bg: if($uiMode == light, #eaeaea, #33382e);
$form-button-inactive-text-color: if($uiMode == light, #999, #777);

$alert-bg: if($uiMode == light, #fff, #141414);
$alert-bg-dark: if($uiMode == light, #f1f1f1, #222);

$not-found-error-text-color: if($uiMode == light, #dddddd, #222);

$dashboard-header-submenu-hover: if($uiMode == light, #f3f8ee, #102201);

$services-bg: if($uiMode == light, #f7fdf2, #0e1b04);

$service-bg-1: if($uiMode == light, #fcecd8, #2f1b04);
$service-bg-2: if($uiMode == light, #e7f2de, #18250e);
$service-bg-3: if($uiMode == light, #dff8ff, #002733);

$transaction-label-bg-deposit: if($uiMode == light, #f2f9ec, #253913);
$transaction-label-bg-withdraw: if($uiMode == light, #ffe5e5, #4d0000);
$transaction-label-bg-trade: if($uiMode == light, #fff5e5, #4d2f00);

$table-hover: if($uiMode == light, #f7f7f7, #222);

$currencies-scrollbar-track: if($uiMode == light, #f7f7f7, #222);
$currencies-scrollbar-track-light: if($uiMode == light, #efefef, #222);
$currencies-scrollbar-thumb: if($uiMode == light, #e2ecd8, #444);

$currency-bg-1: if($uiMode == light, #f9f9f9, #0e1d01);
$currency-bg-1-hover: if($uiMode == light, #f5f5f5, #102201);
$currency-bg-2: if($uiMode == light, #efefef, #132702);
$currency-bg-2-hover: if($uiMode == light, #e7e7e7, #102201);

$wallet-card-action-bg: if($uiMode == light, #f8fbf7, #142702);

$wallet-card-action-active-color: if($uiMode == light, #244b01, #fff);
$wallet-card-action-active-icon-bg: if($uiMode == light, #244b01, #448c04);
$wallet-card-action-active-icon-color: if($uiMode == light, #448c04, #fff);

$mt-giftcards-header: if($uiMode == light, #ebebeb, #19260d);

$giftcard-transactions-header: if($uiMode == light, #f8fbf7, #19260d);

$pay-bills-info-bg-2: if($uiMode == light, #eef3ea, rgba(34, 54, 40, 0.4));
$pay-bills-right-header-bg: if(
  $uiMode == light,
  #f8fbf7,
  rgba(34, 54, 40, 0.4)
);
$pay-bills-right-info-bg: if($uiMode == light, #f9f9f9, #1e2617);

$currency-modal-bg: if($uiMode == light, #f9f9f9, #131313);
$currency1-bg: if($uiMode == light, #fff, #102201);

$form-disabled: if($uiMode == light, #f1f1f1, #2e3229);

$crypto-block-header: if($uiMode == light, #f4f4f5, #19260d);
$crypto-block-balance-header: if($uiMode == light, #fcfcfc, #33382e);

$chat-bg: if($uiMode == light, #edfade, #1b2c07);
$chat-bg-1: if($uiMode == light, #f9f9f9, #1e2518);
$chat-bg-1-border: if($uiMode == light, "#eaeaea", transparent);

$account-bg-green: if($uiMode == light, "#ecf2e6", "#193102");
$account-bg-yellow: if($uiMode == light, "#fff6e0", "#332400");

$shadow-bg: if($uiMode == light, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.8));

$radio-bg: if($uiMode == light, #ccc, #1e2617);

$usd-color: if($uiMode == light, #397305, #fff);

$info-bg: if($uiMode == light, #e6ece1, #0c1901);

$wallet-actions-mobile-bg: if($uiMode == light, #eff6e8, #0e1b04);

$vcard-bg: if($uiMode == light, #f2f5ef, #2d3922);
$vcard-user-bg: if($uiMode == light, #fafafa, #1e2617);

$vcard-actions-bg: if($uiMode == light, #f2f5ef, #0f130b);
$vcard-card-info-bg: if($uiMode == light, #f2f5ef, #1e2617);
$vcard-transaction-bg: if($uiMode == light, #fafafa, #132701);

$dark-primary-bg: if($uiMode == light, #ffffff, #448c04);
$light-primary-color: if($uiMode == light, #448c04, #ffffff);

$options-bg: if($uiMode == light, #f6f6f6, #102201);
$option-bg: if($uiMode == light, #ffffff, #173201);

$virtual-account-bg: if($uiMode == light, #ffffff, #0a1503);

$featured-heading-color: if($uiMode == light, #bfbfbf, #808080);

$featured-color: if($uiMode == light, #1a1a1a, #d9d9d9);
$featured-active-color: if($uiMode == light, #fff, #d9d9d9);

$vcard-home-bg: if($uiMode == light, #e2eff9, #091c2a);

$vcard-heading-color: if($uiMode == light, #0f0f1f, #d9d9d9);
$vcard-text-color: if($uiMode == light, #545454, #b3b3b3);

$cnotice-bg: if($uiMode == light, #ffffff, #0f1d01);
$cnotice-main-bg: if($uiMode == light, #fdf2e7, #2f1904);
$cnotice-info-bg: if($uiMode == light, #ffffff, #0a1401);

:root {
  --light-block: #{$light-block};
  --light-inline-block: #{$light-inline-block};

  --dark-block: #{$dark-block};
  --dark-inline-block: #{$dark-inline-block};

  --body-bg: #{$body-bg};

  --util-bg: #{$util-bg};
  --util-border: #{$util-border};

  --vcard-bg: #{$vcard-bg};
  --vcard-user-bg: #{$vcard-user-bg};

  --vcard-actions-bg: #{$vcard-actions-bg};
  --vcard-card-info-bg: #{$vcard-card-info-bg};
  --vcard-transaction-bg: #{$vcard-transaction-bg};

  --dark-primary-bg: #{$dark-primary-bg};
  --light-primary-color: #{$light-primary-color};

  --options-bg: #{$options-bg};
  --option-bg: #{$option-bg};

  --wallet-actions-mobile-bg: #{$wallet-actions-mobile-bg};

  --text-color: #{$text-color};
  --text-color-dark: #{$text-color-dark};
  --text-color-primary: #{$text-color-primary};

  --header-bg: #{$header-bg};

  --hero-bg: #{$hero-bg};
  --hero-heading-color: #{$hero-heading-color};
  --hero-description-color: #{$hero-description-color};

  --stats-bg: #{$stats-bg};
  --stats-icon-bg: #{$stats-icon-bg};

  --border: #{$border};
  --border-2: #{$border-2};
  --border-3: #{$border-3};

  --feature-bg-1: #{$feature-bg-1};
  --feature-bg-2: #{$feature-bg-2};
  --feature-bg-3: #{$feature-bg-3};
  --feature-bg-4: #{$feature-bg-4};

  --feature-heading-color: #{$feature-heading-color};
  --feature-description-color: #{$feature-description-color};
  --feature-link-color: #{$feature-link-color};

  --market-bg: #{$market-bg};
  --market-table-bg: #{$market-table-bg};
  --market-border: #{$market-border};

  --sort-icon: #{$sort-icon};
  --sort-icon-active: #{$sort-icon-active};

  --why-choose-bg: #{$why-choose-bg};

  --testimonial-bg: #{$testimonial-bg};
  --testimonial-bg-2: #{$testimonial-bg-2};

  --footer-bg: #{$footer-bg};

  --accordion-bg: #{$accordion-bg};
  --accordion-shadow: #{$accordion-shadow};
  --accordion-border: #{$accordion-border};
  --accordion-hover: #{$accordion-hover};

  --form-bg: #{$form-bg};

  --section-header-bg: #{$section-header-bg};
  --section-search-form-bg: #{$section-search-form-bg};

  --preloader-bg-1: #{$preloader-bg-1};
  --preloader-bg-2: #{$preloader-bg-2};

  --placeholder-bg: #{$placeholder-bg};
  --placeholder-bg-2: #{$placeholder-bg-2};

  --form-button-inactive-bg: #{$form-button-inactive-bg};
  --form-button-inactive-text-color: #{$form-button-inactive-text-color};

  --alert-bg: #{$alert-bg};
  --alert-bg-dark: #{$alert-bg-dark};

  --not-found-error-text-color: #{$not-found-error-text-color};

  --dashboard-header-submenu-hover: #{$dashboard-header-submenu-hover};

  --services-bg: #{$services-bg};

  --service-bg-1: #{$service-bg-1};
  --service-bg-2: #{$service-bg-2};
  --service-bg-3: #{$service-bg-3};

  --transaction-label-bg-deposit: #{$transaction-label-bg-deposit};
  --transaction-label-bg-withdraw: #{$transaction-label-bg-withdraw};
  --transaction-label-bg-trade: #{$transaction-label-bg-trade};

  --table-hover: #{$table-hover};

  --currencies-scrollbar-track: #{$currencies-scrollbar-track};
  --currencies-scrollbar-track-light: #{$currencies-scrollbar-track-light};
  --currencies-scrollbar-thumb: #{$currencies-scrollbar-thumb};

  --currency-bg-1: #{$currency-bg-1};
  --currency-bg-1-hover: #{$currency-bg-1-hover};
  --currency-bg-2: #{$currency-bg-2};
  --currency-bg-2-hover: #{$currency-bg-2-hover};

  --wallet-card-action-bg: #{$wallet-card-action-bg};

  --wallet-card-action-active-color: #{$wallet-card-action-active-color};
  --wallet-card-action-active-icon-bg: #{$wallet-card-action-active-icon-bg};
  --wallet-card-action-active-icon-color: #{$wallet-card-action-active-icon-color};

  --mt-giftcards-header: #{$mt-giftcards-header};

  --giftcard-transactions-header: #{$giftcard-transactions-header};

  --pay-bills-info-bg-2: #{$pay-bills-info-bg-2};
  --pay-bills-right-header-bg: #{$pay-bills-right-header-bg};
  --pay-bills-right-info-bg: #{$pay-bills-right-info-bg};

  --currency-modal-bg: #{$currency-modal-bg};
  --currency1-bg: #{$currency1-bg};

  --form-disabled: #{$form-disabled};

  --crypto-block-header: #{$crypto-block-header};
  --crypto-block-balance-header: #{$crypto-block-balance-header};

  --chat-bg: #{$chat-bg};
  --chat-bg-1: #{$chat-bg-1};
  --chat-bg-1-border: #{$chat-bg-1-border};

  --account-bg-green: #{$account-bg-green};
  --account-bg-yellow: #{$account-bg-yellow};

  --shadow-bg: #{$shadow-bg};
  --radio-bg: #{$radio-bg};

  --usd-color: #{$usd-color};

  --info-bg: #{$info-bg};

  --virtual-account-bg: #{$virtual-account-bg};

  --featured-heading-color: #{$featured-heading-color};

  --featured-color: #{$featured-color};
  --featured-active-color: #{$featured-active-color};

  --vcard-home-bg: #{$vcard-home-bg};

  --vcard-heading-color: #{$vcard-heading-color};
  --vcard-text-color: #{$vcard-text-color};

  --cnotice-bg: #{$cnotice-bg};
  --cnotice-main-bg: #{$cnotice-main-bg};
  --cnotice-info-bg: #{$cnotice-info-bg};
}

$color-primary: #448c04;
$color-primary-light: #659837;
$color-primary-lighter: #7bb20e;
$color-primary-dark: #3f7e05;
$color-primary-darker: #397305;
$color-accent: #ff784b;

$color-white: #e6e6e6;

$color-orange: #f9aa4b;
$color-red: red;

$color-danger: #ff6b6b;
$color-warning: #f08c00;
$color-success: #51cf66;
$color-info: #0f5ffc;
