.crypto-block {
  width: 45rem;
  max-width: 100%;
  margin: 4.8rem auto 9.6rem;
  border-radius: 6px;
  overflow: hidden;

  &__back {
    text-align: center;
    margin-bottom: 1.2rem;

    & span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 0.6rem;
      cursor: pointer;

      & ion-icon {
        display: block;
      }
    }
  }

  &__header {
    background-color: var(--crypto-block-header);
    padding: 3.2rem;
    padding-bottom: 7.8rem;

    &--1 {
      padding: 3.2rem;
    }
  }

  &__heading {
    text-align: center;
    text-transform: uppercase;
  }

  &__body {
    background-color: var(--header-bg);
    padding: 0 3.2rem 3.2rem;

    &--1 {
      padding: 3.2rem;
    }
  }

  &__balance-block {
    padding: 2.4rem 0;
    background-color: var(--crypto-block-balance-header);
    text-align: center;
    border-radius: 6px;

    transform: translateY(-50%);

    display: flex;
    flex-direction: column;
  }

  &__balance-text {
    font-weight: 300;
    font-size: 1.6rem;
  }

  &__balance {
    font-size: 3.4rem;
    font-weight: 500;
    text-transform: uppercase;

    & small {
      font-size: 1.8rem;
    }
  }

  &__body-main {
    margin-top: -4.2rem;

    display: flex;
    flex-direction: column;
    gap: 1.8rem;
  }

  &__rate-info {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    font-size: 1.5rem;

    & p {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    & ion-icon {
      display: block;
      color: $color-primary;
    }

    & p:last-child {
      color: $color-primary;
      margin-left: auto;
    }
  }

  &__pin {
    background-color: var(--crypto-block-header);
    padding: 3.2rem 0;
  }

  &__footer {
    background-color: var(--header-bg);
    padding: 3.2rem;

    display: flex;
    flex-direction: column;
    gap: 1.8rem;
  }
}

.crypto-block2 {
  width: 45rem;
  max-width: 100%;
  margin: 4.8rem auto 9.6rem;
  border-radius: 6px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  gap: 1.8rem;

  &--md {
    width: 55rem;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.6rem;

    & span {
      cursor: pointer;

      & ion-icon {
        display: block;
        width: 2rem;
        height: 2rem;
      }
    }
  }

  &__heading {
    font-size: 2.2rem;
    font-weight: 600;
  }

  &__body {
    background-color: var(--header-bg);
    padding: 2.4rem;
    border-radius: 0.8rem;

    display: flex;
    flex-direction: column;
    gap: 1.8rem;

    &--p0 {
      padding: 0;
      display: block;
    }
  }

  &__flex {
    padding: 2.4rem;

    display: flex;
    flex-direction: column;
    gap: 1.8rem;
  }

  &--md &__flex {
    padding: 5.6rem;

    @media (max-width: 450px) {
      padding: 2.4rem;
    }
  }

  &__swap-block {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__swap-draw-up {
    margin-bottom: -24px;
  }

  &__swap-btn {
    align-self: center;

    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 1px solid var(--border);
    background-color: transparent;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      color: $color-primary;
    }

    &:hover {
      background-color: $color-primary;
    }

    &:hover ion-icon {
      color: #fff;
    }
  }

  &__swap-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.2rem;

    font-size: 1.25rem;
    font-weight: 600;

    & p:last-child {
      color: $color-primary-darker;
      text-align: right;
    }
  }
}

.crypto-final {
  width: 45rem;
  max-width: 100%;
  margin: 4.8rem auto 9.6rem;
  border-radius: 6px;
  overflow: hidden;

  &__header {
    padding: 3.2rem 2.4rem 4.8rem;
    background-color: var(--crypto-block-header);
    // background-color: #333;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
  }

  &__header-header {
    align-self: stretch;

    display: flex;
    align-items: center;
  }

  &__cancel {
    margin-left: auto;
    color: $color-danger;
  }

  &__back {
    align-self: flex-start;

    display: block;
    align-items: center;

    & span {
      cursor: pointer;

      & ion-icon {
        display: block;
        width: 1.8rem;
        height: 1.8rem;
      }
    }
  }

  &__currency {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    font-size: 2rem;

    & img {
      display: block;
      height: 3.2rem;
    }

    & span {
      color: #bdbdbd;
    }
  }

  &__label {
    text-transform: uppercase;
  }

  &__label2 {
    color: #bdbdbd;
  }

  &__address {
    background-color: var(--header-bg);
    text-align: center;
    padding: 1.2rem;
    word-wrap: break-word;
    width: 100%;
  }

  &__amount-block {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.4rem;

    & span {
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  &__body {
    padding: 4rem 2.4rem;
    background-color: var(--header-bg);
  }

  &__pin-sections {
    display: flex;
    flex-direction: column;
    gap: 3.6rem;

    padding: 2.4rem;
    background-color: var(--crypto-block-header);

    &--1 {
      padding: 0;
      background-color: transparent;
    }
  }

  &__pin-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
  }

  &__heading {
    font-size: 2.4rem;
    font-size: 600;
    line-height: 1;
  }

  &__heading-subtext {
    text-align: center;
  }

  &__forgot-pin {
    text-align: center;
    font-size: 1.2rem;
    color: #4f4f4f;
    cursor: pointer;
    text-decoration: underline;
    color: #228be6;
    font-weight: 500;
  }
}
